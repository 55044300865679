import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import bid from '../assets/we.png';
import gh from '../assets/godhand.jpg';
import bt from '../assets/bt.png';
import ankha from '../assets/ankha.png';
import khulan from '../assets/khulan.jpg';
import anujin from '../assets/anujin.png';
import tursuld from '../assets/tursuld.jpg';
import anar from '../assets/anar.png';
import yesh from '../assets/yesh.png';
import more from '../assets/more.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from 'react';
export const Test = () => {
	const gishuud = [
		{
			nameF: 'Bilguuntodrokh Batsukh',
			img: '../assets/bt.png',
			position: 'CEO, UI/UX Designer, Project Manager, Full-Stack Developer',
			fb: 'https://www.facebook.com/Bilguuntodrokh',
			ig: 'https://www.instagram.com/blgntdrkh/',
			ml: 'bilguutodrokh@gmail.com',
		},
		{
			nameF: 'Anujin Bymbasuren',
			img: '../assets/anujin.png',
			position: 'CEO, UI/UX Designer, Project Manager, Full-Stack Developer',
			fb: 'https://www.facebook.com/Bilguuntodrokh',
			ig: 'https://www.instagram.com/blgntdrkh/',
			ml: 'bilguutodrokh@gmail.com',
		},
	];

	const [qfirst, setQfirst] = useState(false);
	useEffect(() => {
		document.title = 'Tmull ・ Туршилтын шалгалт';
	}, []);
	return (
		<div className="mt-24 lg:mt-28 font-semibold dark:text-white">
			<div className="mt-8 lg:mt-12 xl:mt-16 2xl:mt-20 flex justify-center flex-col items-center">
				<div className="text-[#4BAB4D]">Туршилтын шалгалтууд</div>
				<div className="text-lg xl:text-xl 2xl:text-2xl w-full text-center">
					Өөрийгөө сорьж, чадвараа хөгжүүлж, туршлага хуримтлуулцгаая
				</div>
				<div class="flex flex-wrap gap-3 lg:gap-6 mt-4 w-full justify-center items-center">
					<div className="w-full cursor-pointer overflow-hidden md:w-72 lg:w-96 bg-neutral-100 dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 drop-shadow rounded-lg flex justify-center">
						<img className="w-full object-cover" src={yesh} alt="" />
					</div>
					<div className="w-full cursor-pointer overflow-hidden md:w-72 lg:w-96 bg-neutral-100 dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 drop-shadow rounded-lg flex justify-center">
						<img className="w-full object-cover" src={yesh} alt="" />
					</div>
					<div className="w-full cursor-pointer overflow-hidden md:w-72 lg:w-96 bg-neutral-100 dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 drop-shadow rounded-lg flex justify-center">
						<img className="w-full object-cover" src={yesh} alt="" />
					</div>
				</div>
			</div>
			<div className="text-justify font-normal flex flex-col gap-8 xl:text-lg w-full lg:w-4/5 xl:w-3/5">
				<div className="xl:first-letter:text-5xl first-letter:text-4xl first-letter:font-bold first-letter:mr-1 first-letter:mt-1 first-letter:float-left">
					Жил бүр мянга мянган сурагчид АНУ-ын шилдэг их, дээд сургуулиуд руу
					өргөдлөө явуулдаг. Эдгээр өргөдөл гаргагчдын ихэнх нь өндөр gpa болон
					SAT оноотой, өндөр ур чадвартай байдаг бөгөөд Харвард шиг их дээд
					сургуульд элсэх эсэх нь заримдаа азаас шалтгаалдаг. Гэсэн хэдий ч,
					эдгээр өргөдлийн дундаас элссэн сурагчдын өргөдөл, элсэж чадаагүй
					сурагчдын өргөдөлд заримдаа тодорхой ялгаа байдаг. Энэхүү ялгааг илүү
					сайн ойлгохын тулд хоёр сурагчдын (Student А болон Student B)
					Харвардад илгээсэн өргөдөл , тэдний академик болон хичээлээс гадуурх
					амжилтуудыг харьцуулж, яагаад нэг нь тэнцсэн, нөгөө нь тэнцээгүйг
					нарийвчлан харцгаах болно.
				</div>
				<div>
					<b>Сурагч А: Харвардад тэнцсэн</b>
					<ul className=" list-disc">
						<li>
							<b>Голч оноо:</b> 4.0
						</li>
						<li>
							<b>SAT:</b> 1580
						</li>
						<li>
							<b>Хичээлээс гадуур үйл ажиллагаа:</b>
							<ul className="indent-8 list-inside list-disc">
								<li>
									<b>Байгаль орчны клубийн үүсгэн байгуулагч, Тэргүүн: </b>
									<div>
										Сургуулийн хүрээнд хуванцар хог хаягдлыг бууруулах
										санаачилга гаргаж, уг санаачилга нь сургуулийн хүрээнд
										хэрэгжсэн.
									</div>
								</li>
								<li>
									<b>Ахлах сургуулийн теннисний багийн ахлагч: </b>
									<div>
										Багийг бүсийн аварга болгоход удирдан оролцсон, мөн хэд
										хэдэн хувийн шагнал авсан.
									</div>
								</li>
								<li>
									<b>Орон нутгийн сонинд дадлага хийсэн: </b>
									<div>
										Уур амьсгалын өөрчлөлт, байгаль орчны бодлогын талаар
										нийтлэл бичиж, ням гарагийн дугаарт нийтлэгдсэн.
									</div>
								</li>
								<li>
									<b>Орон нутгийн хүнсний банкинд сайн дурын ажилтан: </b>
									<div>
										Хүнсний тусламжийн аян удирдан зохион байгуулж, сайн дурын
										үйл ажиллагаа.
									</div>
								</li>
								<li>
									<b>MIT-ийн зуны хөтөлбөрт хамрагдсан: </b>
									<div>
										Байгаль орчны шинжлэх ухааныг судалж, тогтвортой эрчим
										хүчний шийдлүүдийн талаар судалгааны ажил бичсэн.
									</div>
								</li>
							</ul>
						</li>
						<li>
							<b>Эсээнүүд:</b>
							<ul className="indent-8 list-inside list-disc">
								<li>
									Өөрийнх нь өссөн газар нь бохирдсон голын ойролцоо байсан нь
									түүнийг байгаль орчноо хамгаалахад хэрхэн дуртай болгосныг
									харуулсан.
								</li>
								<li>
									<b>Personal statement: </b>
									<div>
										Харвардын нөөц, профессор, судалгааны төвүүдтэй холбоотой
										гүнзгий ойлголттой байгаагаа харуулсан{' '}
										<b>нэмэлт эсээнүүд.</b>
									</div>
								</li>
							</ul>
						</li>
						<li>
							<b>Recommendation letter:</b>
							<ul className="indent-8 list-inside list-disc">
								<li>
									<b>Шинжлэх ухааны багш: </b>
									<div>
										Байгаль орчны клубт үзүүлсэн манлайлал, байгаль орчны
										шинжлэх ухаанд гүнзгий сонирхолтой талаар онцолсон.
									</div>
								</li>
								<li>
									<b>Англи хэлний багш:</b>
									<div>
										Түүний бичих чадвар, нарийн төвөгтэй санааг үр дүнтэйгээр
										илэрхийлэх чадварыг сайшаасан.
									</div>
								</li>
								<li>
									<b>Теннисний дасгалжуулагч:</b>
									<div>Түүний манлайлал, багийн ажиллагааны талаар ярьсан.</div>
								</li>
								<li>
									<b>Ярилцлага:</b>
									<div>
										Тодорхой зорилготой, Харвард хэрхэн түүний зорилгуудыг
										биелүүлэхэд туслах талаар тодорхой ярьсан хүчтэй ярилцлага.
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div>
					<b>Сурагч Б: Харвардад тэнцээгүй</b>
					<ul className=" list-disc">
						<li>
							<b>Голч оноо:</b> 4.0
						</li>
						<li>
							<b>SAT:</b> 1570
						</li>
						<li>
							<b>Хичээлээс гадуур үйл ажиллагаа:</b>
							<ul className=" indent-8 list-inside list-disc">
								<li>
									<b>Шинжлэх ухааны клубийн тэргүүн:</b>
									<div>
										Долоо хоног бүр уулзалт зохион байгуулж, орон нутгийн
										шинжлэх ухааны тэмцээнүүдэд багаа удирдан оролцсон.
									</div>
								</li>
								<li>
									<b>Ахлах сургуулийн хөлбөмбөгийн багийн ахлагч:</b>
									<div>Багаараа улсын хагас шигшээд шалгарсан.</div>
								</li>
								<li>
									<b>Судалгааны лабораторид дадлагажигч:</b>
									<div>
										Сэргээгдэх эрчим хүчний эх үүсвэрүүдийн талаар туршилт
										явуулсан.
									</div>
								</li>
								<li>
									<b>Сайн дурын багш:</b>
									<div>
										Давуу эрх эдлэх боломжгүй хүүхдүүдэд математикийн болон
										шинжлэх ухааны хичээлүүд заасан.
									</div>
								</li>
								<li>
									<b>Станфордын зуны хөтөлбөрт хамрагдсан:</b>
									<div>
										Биологийн чиглэлээр судалгаа хийж, генетикийн талаар
										судалгааны ажил гүйцэтгэсэн.
									</div>
								</li>
							</ul>
						</li>
						<li>
							<b>Эсээнүүд:</b>
							<ul className="indent-8 list-inside list-disc">
								<li>
									Хувийн эсээндээ шинжлэх ухааныг хайрлах, байгаль ертөнцөд
									сониуч байдалтайгаа харуулсан. <b>(personal statement)</b>
								</li>
								<li>
									<b>Нэмэлт эсээнүүд </b>
									нь сайн бичигдсэн байсан ч Харвардын тодорхой нөөц эсвэл багш
									нартай хүчтэй холбоо үүсгэж чадаагүй.
								</li>
							</ul>
						</li>
						<li>
							<b>Recommendation letter:</b>
							<ul className="indent-8 list-inside list-disc">
								<li>
									<b>Шинжлэх ухааны багш: </b>
									<div>
										Түүний академик чадвар, шинжлэх ухааны судалгаанд
										сонирхолтой байдалтай талаар ярьсан.
									</div>
								</li>
								<li>
									<b>Англи хэлний багш:</b>
									<div>Түүний бичих чадвар, аналитик сэтгэлгээг магтсан.</div>
								</li>
								<li>
									<b>Хөлбөмбөгийн дасгалжуулагч</b>
									<div>
										Түүний манлайлал, өөрийгөө спортод дайчилдаг байдлыг ярьсан.
									</div>
								</li>
								<li>
									<b>Ярилцлага:</b>
									<div>
										Сайн ярилцлага болсон ч хариултууд нь илүү ерөнхий бөгөөд
										Харвард яагаад тохиромжтой болох талаар тодорхойгүй байв.
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<b>Яагаад Сурагч А тэнцэж, харин Сурагч Б тэнцээгүй вэ?</b>
				<ul className="list-decimal">
					<li>
						<b>Төвлөрөл ба хүсэл эрмэлзэл</b>
						<ul>
							<li>
								<b>Сурагч А:</b>
								<div>
									Түүний өргөдөл байгаль орчны асуудалд гүнзгий хүсэл
									эрмэлзэлтэй гэдгийг нь тод харуулсан. Тэрээр байгаль орчны
									клубийг үүсгэн байгуулж, сургуулийнхаа хуванцар хог хаягдлыг
									бууруулах санаачилгыг гаргаж хэрэгжүүлсэн. Мөн MIT-ийн зуны
									хөтөлбөрт хамрагдан тогтвортой эрчим хүчний шийдлүүдийг
									судалсан нь түүний байгаль орчны шинжлэх ухааны талаархи
									гүнзгий сонирхол, хүсэл эрмэлзлийг нотолж байна. Энэ төвлөрөл
									нь түүний өргөдлийн бүх хэсэгт тусгагдсан бөгөөд ингэснээр
									элсэлтийн хороо түүнийг тууштай, тодорхой зорилготой хүн гэж
									харсан.
								</div>
							</li>
							<li>
								<b>Сурагч B:</b>
								<div>
									Академик чадвар сайтай, янз бүрийн үйл ажиллагаанд идэвхтэй
									оролцож байсан ч түүний өргөдөлд тийм ч их төвлөрөл
									харагдаагүй. Тэрээр шинжлэх ухаанд сонирхолтой ч гэсэн яг аль
									талбар, ямар чиглэлээр явмаар байгаа нь ойлгомжгүй байсан.
									Тиймээс, түүний өргөдөл илүү тархмал, зохион байгуулалтгүй
									байсан тул хорооныхонд түүний хүсэл эрмэлзлийг тодорхой
									ойлгоход хүндрэл учирсан.
								</div>
							</li>
						</ul>
					</li>
					<li>
						<b>Харвардтай холбогдох байдал</b>
						<ul>
							<li>
								<b>Сурагч А:</b>
								<div>
									Түүний өргөдөлд Харвардын тодорхой хөтөлбөрүүд, нөөцүүд нь
									түүний зорилгуудыг биелүүлэхэд хэрхэн туслахыг тодорхой
									харуулсан. Тэрээр Харвардын байгаль орчны судалгааны төвүүд,
									профессоруудын талаар гүнзгий судалж, тэдгээрийн
									тусламжтайгаар өөрийн хүсэл эрмэлзлийг хэрхэн үргэлжлүүлэх
									талаар эссэнүүддээ тодорхой дурдсан байна. Энэ нь түүнийг
									Харвардад суралцах нь түүний ирээдүйн зорилго болон карьерын
									төлөвлөгөөтэй хэрхэн нийцэж байгааг харуулсан чухал хүчин зүйл
									байсан.
								</div>
							</li>
							<li>
								<b>Сурагч B:</b>
								<div>
									Түүний академик болон хичээлээс гадуурх чадвар нь сайн байсан
									ч эсээнүүд болон ярилцлага нь Харвардтай өвөрмөц холбоо
									үүсгээгүй. Тэрээр Харвардын тодорхой нөөц эсвэл багш нартай
									гүнзгий холбоо үүсгэж чадаагүй тул элсэлтийн хороонд түүний
									Харвардад суралцах сонирхол нь бусад өрсөлдөгчдөд харьцуулахад
									сул байсан байж магадгүй.
								</div>
							</li>
						</ul>
					</li>
					<li>
						<b>Нөлөөлөл ба манлайлал</b>
						<ul>
							<li>
								<b>Сурагч А:</b>
								<div>
									Тэрээр зөвхөн байгаль орчны клубийн тэргүүн байсан төдийгүй,
									сургуулийн хүрээнд санаачилга гарган, олон нийтийн дунд том
									нөлөө үзүүлж байсан. Энэ нь түүнийг манлайлалын чадвартай,
									нийгэмд эерэг өөрчлөлт авчрах хүсэл эрмэлзэлтэй гэдгийг
									харуулж, элсэн суралцахад томоохон давуу тал болсон.
								</div>
							</li>
							<li>
								<b>Сурагч B:</b>
								<div>
									Тэрээр манлайллын үүрэг гүйцэтгэж байсан ч түүний нөлөөллийн
									цар хүрээ тодорхойгүй байсан. Шинжлэх ухааны клуб болон
									хөлбөмбөгийн багийн ахлагч байсан нь түүний манлайллын
									чадварыг харуулж байгаа ч түүний нийгэмд үзүүлсэн бодит нөлөө
									Сурагч А-тай харьцуулахад сул байсан.
								</div>
							</li>
						</ul>
					</li>
					<li>
						<b>Түүх ба нэгдмэл байдал</b>
						<ul>
							<li>
								<b>Сурагч А:</b>
								<div>
									Түүний өргөдөлд түүний хэн болох, юу түүнийг өдөөдөг,
									Харвардад хүсэл эрмэлзэлээ хэрхэн үргэлжлүүлэх талаар тодорхой
									түүхийг харуулсан. Өөрөөр хэлбэл, түүний өргөдөл нь нэгдмэл,
									ойлгомжтой, өөрийн гэсэн өвөрмөц түүхтэй байсан.
								</div>
							</li>
							<li>
								<b>Сурагч B:</b>
								<div>
									Түүний академик болон хичээлээс гадуурх амжилтууд хүчтэй
									байсан ч түүний түүх тийм ч нэгдмэл, сонирхол татахуйц
									байгаагүй. Энэ нь элсэлтийн хорооны гишүүдийн хувьд түүний
									Харвардад хэрхэн онцгойрохыг харахад хэцүү болгосон.
								</div>
							</li>
						</ul>
					</li>
				</ul>
				<div className="gap-2 flex flex-col">
					<b>ДҮГНЭЛТ</b>
					<div className="indent-8">
						Сурагч A нь өөрийн шинжлэх ухаан, байгаль орон зүйд анхаарах
						сонирхол өөрийнх нь өсөж торнисон амьдралтай нь шууд хамааралтай
						болохыг өөрийн эссэнд тусгаж өгсөн бөгөөд энэ нь өөрийг нь
						тодорхойлох үндэс болсон гэж болно. Хэдий хоёр сурагч хоёул
						хичээлээс гадуурх үйл ажиллагаа, сурлага дээр гоцгойд байсан ч хоёр
						дахь сурагчын сонирхол бөгөөд хийсэн үйл ажиллагаанууд олон тал руу
						чиглэж тарсан байдалтайг харж болно.
					</div>
					<div className="indent-8">
						Үүнээс ажиглахад, та өөрийн хийж буй үйл ажиллагаа мөн судалж бүй
						зүйлс тань хоорондоо хэрхэн холбоо хамааралтай байгааг, мөн
						цаашилбал таны сонирхож буй салбар таньтай ямар холбоотой, таньд
						яагаад чухал вэ гэдгийг тодорхой гаргаж өгөх нь зөв юм.
					</div>
				</div>
				<div className="gap-2 flex flex-col">
					<div>Жишээ:</div>
					<div>
						<b>Сурагч A: </b>
						амьдарч буй орчны бохирдлоос болон байгалийн салбарт сонирхолтой
						гэдгээ илэрхийлсэн.
					</div>
					<div>
						<b>Сурагч B: </b>
						шинжлэх ухааныг сурах сонирхолтой гэдгээ дурдсан.
					</div>
				</div>
			</div>
		</div>
	);
};
