import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// import mhz from '../assets/bid.png';
import gh from '../assets/godhand.jpg';
import nova from '../assets/nova.png';
import erdem from '../assets/erdem.svg';
import Ielts from '../assets/Rectangle_Ielts.png';
import pdf from '../assets/pdf.svg';
import download from '../assets/download.svg';
import data from '../assets/data.svg';
import vol from '../assets/vol.svg';
import todo from '../assets/todo.svg';
import academy from '../assets/academy.svg';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from 'react';
export const Archive = () => {
	const link = [
		{
			n: 'Бүрэн хэмжээний SAT дадлагын тест',
			t: 'sat',
			url: 'https://satsuite.collegeboard.org/sat/practice-preparation/practice-tests/linear#',
		},
		{
			n: 'English Vocabulary in Use - Intermediate',
			t: 'ielts',
			url: 'https://www.facebook.com/share/p/LVDk7nfNUZxF2Ucq/',
		},
		{
			n: 'Cambridge - English Grammar in Use (Advanced) (2005)',
			t: 'ielts',
			url: 'https://www.facebook.com/share/p/wKgUB4WHgdypUSJr/',
		},
		{
			n: 'Cambridge - English Grammar in Use (Intermediate) (2005)',
			t: 'ielts',
			url: 'https://www.facebook.com/share/p/bXTZ9HveVS4wPMHV/',
		},
		{
			n: 'Cambridge - English Grammar in Use (Essential) (3rd Ed) (2007)',
			t: 'ielts',
			url: 'https://www.facebook.com/share/p/bXTZ9HveVS4wPMHV/',
		},
		{
			n: 'Acing the New SAT Math PDF Book',
			t: 'sat',
			url: 'https://drive.google.com/file/d/1EGgaU7l4_RTIjBbQDzzYgC7d-ehPK3Zz/view',
		},
		{
			n: 'IELTS Grammar',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/18ziWg4_8uzcT2FBZP8fnjwl6QQ8IPcfW/view?usp=drive_link',
		},
		{
			n: 'Official Cambridge Guide to IELTS',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/16IklwLRDIPoVUi8PgP2MkAWvr9wm1zX4/view?usp=drive_link',
		},
		{
			n: 'Grammar for IELTS',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1yRlGghm8FQLI-a3qeP6meNH7X8ZFmoLH/view?usp=drive_link',
		},
		{
			n: 'Vocabulary for IELTS by Cambridge',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1DZAPEVYNrDrA3OsXhC4my1R5AIZbGHkJ/view?usp=drive_link',
		},
		{
			n: 'IELTS-Trainer',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1K2MSmlS2ui5yjnMioHnC9Y7Aj6D9uGXV/view?usp=drive_link',
		},
		{
			n: 'New Insight Into IELTS',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1vsYe_pI2syzHI-NRzREaR_ZaWc20qQsC/view?usp=drive_link',
		},
		{
			n: 'Ace The IELTS',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1DyKkCwXCbfJvNOxWZVuLqQHZFV84WCRz/view?usp=drive_link',
		},
		{
			n: 'Check Vocabulary for IELTS',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1iQMeCfybchHH6Inor7kOBTsLAvZ4y-X6/view?usp=drive_link',
		},
		{
			n: 'Offical IELTS Practice Materials 1',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1SEogZ3Z94LwCOS2sf6LnNIC__I6-K1IM/view?usp=drive_link',
		},
		{
			n: 'Official IELTS Practice Materials 2',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1sgINcAtbltmIBd3JvYp5WwkiBpKxCyBO/view?usp=drive_link',
		},
		{
			n: 'Objective Intermediate',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1MNeAz3WyKdMeG6BMfbcvTeqVFojgIyC5/view?usp=drive_link',
		},
		{
			n: 'Objective Advanced',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1m1kRxg8XuQXgzOIeI7yT2o0kIACtFiCW/view?usp=drive_link',
		},
		{
			n: 'IELTSTests Oxford',
			t: 'ielts',
			url: 'https://drive.google.com/file/d/1Lj3cx_zexdc7NLffOIZtXBN-TeFbaauD/view?usp=drive_link',
		},
		{
			n: 'hack_the_college_essay_2017',
			t: 'essay',
			url: 'https://drive.google.com/file/d/1iCdoMYqVkUQxgjYXtcbVohncV7RL_S1-/view?usp=drive_link',
		},
		{
			n: '50 Successful Ivy League Application Essays',
			t: 'essay',
			url: 'http://www.qianmu.org/u/lystu/school/file/0hme5mn180002vc/0hmlotdce00040a.pdf',
		},
		{
			n: 'TOEFL iBT® Free Practice Test',
			t: 'toefl',
			url: 'https://www.ets.org/pdfs/toefl/toefl-ibt-free-practice-test.pdf',
		},
		{
			n: 'Preparing for the ACT Test 2023–2024',
			t: 'act',
			url: 'https://www.act.org/content/dam/act/unsecured/documents/Preparing-for-the-ACT.pdf',
		},
		{
			n: `McGraw-Hill's 10 ACT Practice Tests`,
			t: 'act',
			url: 'https://www.tcboe.org/site/handlers/filedownload.ashx?moduleinstanceid=5825&dataid=745&FileName=10_ACT_Practice_Tests.pdf',
		},
		// {
		// 	n: '55 Official SAT PDFs & 96 Official ACT PDFs',
		// 	t: '',
		// 	url: '',
		// },
		// {
		// 	n: '',
		// 	t: 'ielts',
		// 	url: '',
		// },
		// {
		// 	n: '',
		// 	t: 'ielts',
		// 	url: '',
		// },
	];

	useEffect(() => {
		document.title = 'Tmull ・ Нөөц';
	}, []);
	return (
		<div className="mt-24 lg:mt-28 font-semibold flex justify-center flex-col w-full items-center dark:text-white">
			<div className="font-semibold flex flex-row items-center justify-center lg:w-[60%] text-center">
				<div className="text-xl md:text-2xl lg:text-3xl">
					Манай онцлох хэрэгтэй хэрэгслүүд
				</div>
			</div>
			<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
				<div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
					<a
						href="/noots/hiih_zuils"
						className="hover:bg-black/5 dark:hover:bg-white/5 p-2 xl:p-4 rounded-xl cursor-pointer group transition-all delay-0 duration-300 ease-in-out transform"
					>
						<svg
							class="flex-shrink-0 size-9 text-gray-800 dark:text-white"
							width="400"
							height="393"
							viewBox="0 0 400 393"
							fill="currentColor"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M294.027 0C281.791 0 271.683 5.11965 262.116 12.4189C253.052 19.3343 242.988 29.3988 230.92 41.4683L126.799 145.587C118.804 153.57 112.482 159.881 108.604 167.948C104.727 176.015 103.748 184.895 102.51 196.125L102.173 199.16C99.857 220.003 97.9475 237.183 98.3846 250.716C98.8416 264.87 101.9 277.834 112.033 287.967C122.166 298.1 135.13 301.158 149.284 301.615C162.817 302.052 179.997 300.143 200.839 297.827L203.876 297.49C215.105 296.252 223.985 295.273 232.052 291.396C240.119 287.518 246.43 281.197 254.412 273.201L358.531 169.081C370.601 157.012 380.666 146.948 387.581 137.884C394.88 128.317 400 118.209 400 105.973C400 93.7364 394.88 83.6285 387.581 74.0617C380.666 64.9979 370.601 54.9341 358.532 42.8653L357.135 41.4689C345.066 29.3991 335.002 19.3344 325.938 12.4189C316.371 5.11965 306.264 0 294.027 0ZM347.734 139.531L260.469 52.2658C268.175 44.6735 274.136 39.0904 279.401 35.0733C286.433 29.708 290.526 28.4954 294.027 28.4954C297.528 28.4954 301.621 29.708 308.654 35.0733C316.015 40.6895 324.734 49.3667 337.684 62.3161C350.633 75.2655 359.31 83.9855 364.927 91.3464C370.292 98.3785 371.505 102.472 371.505 105.973C371.505 109.474 370.292 113.567 364.927 120.599C360.91 125.864 355.327 131.825 347.734 139.531ZM240.296 72.3907L327.609 159.704L235.636 251.677C225.616 261.697 222.848 264.204 219.708 265.713C216.568 267.222 212.881 267.818 198.798 269.383C176.572 271.852 161.444 273.498 150.204 273.135C139.345 272.784 134.962 270.597 132.182 267.818C129.403 265.038 127.216 260.655 126.865 249.796C126.502 238.556 128.148 223.429 130.617 201.202C132.182 187.119 132.778 183.432 134.287 180.292C135.797 177.152 138.303 174.384 148.323 164.364L240.296 72.3907Z"
							/>
							<path d="M147.397 50.8825C155.265 50.7882 161.567 44.3333 161.473 36.4651C161.378 28.5969 154.923 22.2949 147.055 22.3892C100.959 22.9418 69.3186 25.464 45.0271 43.1128C35.7539 49.8502 27.5989 58.0052 20.8615 67.2784C9.73257 82.5961 4.74935 100.536 2.35045 122.678C-0.000276089 144.375 -0.000152612 171.723 2.51399e-06 206.612V208.331C-0.000152612 243.219 -0.000276089 270.567 2.35045 292.264C4.74935 314.406 9.73257 332.346 20.8615 347.664C27.5989 356.937 35.7539 365.092 45.0272 371.83C60.3448 382.958 78.2851 387.942 100.427 390.341C122.123 392.691 149.471 392.691 184.358 392.691H186.08C220.967 392.691 248.316 392.691 270.013 390.341C292.155 387.942 310.095 382.958 325.413 371.83C334.686 365.092 342.841 356.937 349.578 347.664C367.227 323.372 369.749 291.732 370.302 245.636C370.396 237.768 364.094 231.313 356.226 231.218C348.358 231.124 341.903 237.426 341.808 245.294C341.239 292.802 338.041 315.065 326.525 330.915C321.545 337.769 315.518 343.796 308.663 348.776C299.013 355.788 286.661 359.875 266.944 362.011C246.96 364.176 221.154 364.196 185.22 364.196C149.286 364.196 123.48 364.176 103.496 362.011C83.7789 359.875 71.4268 355.788 61.7763 348.776C54.9222 343.796 48.8946 337.769 43.9147 330.915C36.9032 321.264 32.8162 308.912 30.68 289.195C28.5149 269.211 28.4954 243.405 28.4954 207.471C28.4954 171.537 28.5149 145.731 30.68 125.747C32.8162 106.03 36.9032 93.6781 43.9147 84.0276C48.8946 77.1734 54.9222 71.1458 61.7763 66.166C77.6264 54.6502 99.8893 51.452 147.397 50.8825Z" />
						</svg>

						<div class="transition-all delay-0 duration-300 ease-in-out transform bg-gradient-to-r from-gray-200 via-gray-50 to-transparent h-0.5 mt-6 dark:group-hover:from-[#037F00] group-hover:from-[#037F00] dark:group-hover:via-[#027f009f] group-hover:via-[#027f009f] dark:from-neutral-700 dark:via-neutral-900">
							<div class="bg-[#037F00] w-9 h-0.5"></div>
						</div>
						<div class="mt-5">
							<h3 class="text-lg font-semibold text-gray-800 dark:text-white">
								To Do List
							</h3>
							<p class="mt-1 text-gray-600 dark:text-neutral-400">
								Хийх хэрэгтэй жагсаалт гаргаснаар явах чиглэлээ тодорхойлоход
								хялбар болдог
							</p>
						</div>
					</a>

					<a
						href="/noots/sur_dataset"
						className="hover:bg-black/5 dark:hover:bg-white/5 p-2 xl:p-4 rounded-xl cursor-pointer group transition-all delay-0 duration-300 ease-in-out transform"
					>
						<svg
							class="flex-shrink-0 size-9 text-gray-800 dark:text-white"
							width="56"
							height="77"
							viewBox="0 0 56 77"
							fill="none"
							stroke="currentColor"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.2285 8.69178C7.42965 12.7143 2.88672 19.7858 3.00215 28.3441C3.08258 34.3104 5.25316 39.7721 8.81489 44.0301L25.6736 67.6723C26.8129 69.2701 29.1869 69.2701 30.3262 67.6723L47.185 44.0301C48.6428 42.143 51.449 36.8572 51.9439 35.212C52.6307 32.9285 53 30.5074 53 28.0001C53 14.1769 41.7811 2.97405 27.9519 3.00005C22.0161 3.01119 16.5328 5.15434 12.2272 8.69178M16.5553 27.8402V34.5844C16.5553 35.7681 17.1972 36.861 18.2328 37.4342C24.7133 41.0224 31.1944 41.0223 37.6757 37.4342C38.1842 37.1521 38.608 36.7391 38.903 36.238C39.198 35.7368 39.3534 35.166 39.3532 34.5844V27.8402M33.7771 62.8316C41.6057 63.5588 53 65.7259 53 68.2859C53 71.4416 38.6514 74.0002 27.9999 74.0002C17.3485 74.0002 3.00215 71.4416 3.00215 68.2859C3.00215 65.7259 14.3942 63.5588 22.2228 62.8316M25.7179 30.8184L14.4388 24.8466C13.2835 24.2349 13.2835 22.5798 14.4388 21.9682L25.7189 15.9963C26.4232 15.6235 27.2079 15.4286 28.0046 15.4286C28.8015 15.4286 29.5862 15.6235 30.2904 15.9963L41.5704 21.9682C42.7257 22.5798 42.7257 24.2349 41.5704 24.8466L30.2894 30.8185C29.5852 31.1914 28.8005 31.3862 28.0036 31.3861C27.2069 31.3861 26.4222 31.1911 25.7179 30.8184Z"
								stroke-width="5"
								stroke-miterlimit="10"
							/>
						</svg>

						<div class="transition-all delay-0 duration-300 ease-in-out transform bg-gradient-to-r from-gray-200 via-gray-50 to-transparent h-0.5 mt-6 dark:group-hover:from-[#037F00] group-hover:from-[#037F00] dark:group-hover:via-[#027f009f] group-hover:via-[#027f009f] dark:from-neutral-700 dark:via-neutral-900">
							<div class="bg-[#037F00] w-9 h-0.5"></div>
						</div>
						<div class="mt-5">
							<h3 class="text-lg font-semibold text-gray-800 dark:text-white">
								Их сургуулийн дата багц
							</h3>
							<p class="mt-1 text-gray-600 dark:text-neutral-400">
								Сургуулиудын талаарх мэдээллийг цаг алдалгүй гүйлгэж, цагаа
								хэмнэцгээе
							</p>
						</div>
					</a>

					<a
						href="/noots/surgalt"
						className="hover:bg-black/5 dark:hover:bg-white/5 p-2 xl:p-4 rounded-xl cursor-pointer group transition-all delay-0 duration-300 ease-in-out transform"
					>
						<svg
							class="flex-shrink-0 size-9 text-gray-800 dark:text-white"
							width="400"
							height="357"
							viewBox="0 0 400 357"
							fill="currentColor"
							stroke="currentColor"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M254.054 0C209.274 0 172.973 36.3012 172.973 81.0811C172.973 125.861 209.274 162.162 254.054 162.162C298.834 162.162 335.135 125.861 335.135 81.0811C335.135 36.3012 298.834 0 254.054 0ZM205.405 81.0811C205.405 54.2132 227.186 32.4324 254.054 32.4324C280.922 32.4324 302.703 54.2132 302.703 81.0811C302.703 107.949 280.922 129.73 254.054 129.73C227.186 129.73 205.405 107.949 205.405 81.0811Z"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M189.189 194.595C144.409 194.595 108.108 230.896 108.108 275.676C108.108 320.455 144.409 356.757 189.189 356.757H318.919C363.699 356.757 400 320.455 400 275.676C400 230.896 363.699 194.595 318.919 194.595H189.189ZM140.541 275.676C140.541 248.808 162.321 227.027 189.189 227.027H318.919C345.787 227.027 367.568 248.808 367.568 275.676C367.568 302.544 345.787 324.324 318.919 324.324H189.189C162.321 324.324 140.541 302.544 140.541 275.676Z"
							/>
							<path d="M162.208 138.133C158.798 132.655 152.399 129.73 145.946 129.73C119.078 129.73 97.2973 107.949 97.2973 81.0811C97.2973 54.2132 119.078 32.4324 145.946 32.4324C152.399 32.4324 158.798 29.5076 162.208 24.0291C162.359 23.7868 162.511 23.5452 162.664 23.3041C168.342 14.3418 165.465 1.6519 154.919 0.490881C151.973 0.166508 148.979 0 145.946 0C101.166 0 64.8649 36.3012 64.8649 81.0811C64.8649 125.861 101.166 162.162 145.946 162.162C148.979 162.162 151.973 161.996 154.919 161.671C165.465 160.51 168.342 147.82 162.664 138.858C162.511 138.617 162.359 138.375 162.208 138.133Z" />
							<path d="M96.3954 309.554C93.941 305.459 89.6085 302.703 84.8343 302.703H81.0811C54.2132 302.703 32.4324 280.922 32.4324 254.054C32.4324 227.186 54.2132 205.405 81.0811 205.405H84.8343C89.6085 205.405 93.941 202.649 96.3954 198.554C102.387 188.558 95.8851 172.973 84.2312 172.973H81.0811C36.3012 172.973 0 209.274 0 254.054C0 298.834 36.3012 335.135 81.0811 335.135H84.2312C95.8851 335.135 102.387 319.55 96.3954 309.554Z" />
						</svg>

						<div class="transition-all delay-0 duration-300 ease-in-out transform bg-gradient-to-r from-gray-200 via-gray-50 to-transparent h-0.5 mt-6 dark:group-hover:from-[#037F00] group-hover:from-[#037F00] dark:group-hover:via-[#027f009f] group-hover:via-[#027f009f] dark:from-neutral-700 dark:via-neutral-900">
							<div class="bg-[#037F00] w-9 h-0.5"></div>
						</div>
						<div class="mt-5">
							<h3 class="text-lg font-semibold text-gray-800 dark:text-white">
								Тусалж болохуйц сургалтууд
							</h3>
							<p class="mt-1 text-gray-600 dark:text-neutral-400">
								Хүн болгон адил байдаггүйтэй холбоотой, хүн бүрд өөр өөр
								зөвлөгөө хэрэгтэй болдог
							</p>
						</div>
					</a>

					<a
						href="/noots/uil_ajillagaa"
						className="hover:bg-black/5 dark:hover:bg-white/5 p-2 xl:p-4 rounded-xl cursor-pointer group transition-all delay-0 duration-300 ease-in-out transform"
					>
						<svg
							class="flex-shrink-0 size-9 text-gray-800 dark:text-white"
							width="31"
							height="32"
							viewBox="0 0 31 32"
							fill="currentColor"
							stroke="currentColor"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M11.4477 22.4359L10.7971 26.0503C10.7714 26.2054 10.7969 26.3646 10.8697 26.5039C11.0548 26.8576 11.4916 26.9944 11.8453 26.8093L15.0983 25.1105L18.3513 26.8093L18.6766 26.8816C18.8334 26.8844 18.9865 26.8334 19.1103 26.737C19.2163 26.6596 19.2992 26.5548 19.3502 26.4338C19.4011 26.3128 19.4182 26.1802 19.3995 26.0503L18.7489 22.4358L21.3875 19.9057C21.4803 19.8054 21.5457 19.6828 21.5773 19.5499C21.609 19.4169 21.6058 19.2781 21.5682 19.1467C21.5203 19.0253 21.4419 18.9182 21.3406 18.8359C21.2393 18.7536 21.1185 18.6988 20.9899 18.6768L17.3754 18.1346L15.7489 14.8454C15.678 14.699 15.5598 14.5808 15.4134 14.51C15.0541 14.336 14.6218 14.4862 14.4477 14.8454L12.8212 18.1346L9.20674 18.6768C9.07808 18.6988 8.95726 18.7536 8.85596 18.8359C8.75465 18.9182 8.67629 19.0253 8.62844 19.1467C8.59079 19.2781 8.58764 19.4169 8.61929 19.5499C8.65095 19.6828 8.71633 19.8054 8.80914 19.9057L11.4477 22.4359ZM13.4357 19.5082C13.5473 19.4915 13.6526 19.446 13.7413 19.3763C13.83 19.3066 13.8991 19.215 13.9417 19.1106L15.0983 16.7973L16.2549 19.1106C16.2975 19.215 16.3666 19.3066 16.4553 19.3763C16.544 19.446 16.6493 19.4915 16.7609 19.5082L19.3272 19.8696L17.4838 21.6407C17.3991 21.7245 17.3354 21.8271 17.2977 21.9401C17.26 22.0531 17.2495 22.1734 17.2669 22.2913L17.7006 24.8575L15.4235 23.6286L15.0982 23.5563L14.7729 23.6286L12.4958 24.8575L12.9295 22.2913C12.947 22.1734 12.9365 22.0531 12.8988 21.9401C12.8611 21.827 12.7973 21.7245 12.7127 21.6407L10.8693 19.8696L13.4357 19.5082Z" />
							<path d="M27.355 4.25423C27.2936 4.25173 27.2323 4.25134 27.1709 4.25306H24.8577V2.26508C24.8577 1.46989 24.0987 1 23.3035 1H21.171C20.3758 1 19.7975 1.46989 19.7975 2.26508V4.25299H11.1227V2.26508C11.1227 1.46989 10.5444 1 9.74925 1H7.61668C6.82156 1 6.06248 1.46989 6.06248 2.26508V4.25299H3.74928C2.27264 4.21183 1.04221 5.37555 1.00105 6.85213C0.999321 6.91342 0.999736 6.97486 1.00223 7.03616V27.9277C1.00223 29.5181 2.15883 31 3.74921 31H27.171C28.7614 31 29.918 29.5181 29.918 27.9277V7.03616C29.9784 5.5602 28.8309 4.3147 27.355 4.25423ZM21.171 2.44579H23.4119V6.42167H21.171V2.44579ZM7.61675 2.44579H9.67702V6.42167H7.61675V2.44579ZM28.4722 27.9277C28.4722 28.7229 27.9662 29.5542 27.171 29.5542H3.74928C2.95409 29.5542 2.44808 28.7229 2.44808 27.9277V12.5663H28.4722V27.9277ZM28.4722 7.03616V11.1205H2.44801V7.03616C2.38679 6.36024 2.88511 5.76263 3.56103 5.70141C3.6236 5.69575 3.68651 5.69489 3.74921 5.69885H6.06248V6.49404C6.06248 7.28923 6.82149 7.86753 7.61668 7.86753H9.74925C10.4876 7.8878 11.1025 7.30562 11.1227 6.5673C11.1234 6.54288 11.1234 6.51846 11.1227 6.49404V5.69878H19.7975V6.49397C19.7772 7.23229 20.3594 7.84719 21.0977 7.86746C21.1221 7.86815 21.1465 7.86815 21.171 7.86746H23.3035C24.0987 7.86746 24.8577 7.28916 24.8577 6.49397V5.69878H27.171C27.8484 5.65609 28.4321 6.17061 28.4748 6.84798C28.4787 6.91068 28.4779 6.97359 28.4722 7.03616Z" />
						</svg>

						<div class="transition-all delay-0 duration-300 ease-in-out transform bg-gradient-to-r from-gray-200 via-gray-50 to-transparent h-0.5 mt-6 dark:group-hover:from-[#037F00] group-hover:from-[#037F00] dark:group-hover:via-[#027f009f] group-hover:via-[#027f009f] dark:from-neutral-700 dark:via-neutral-900">
							<div class="bg-[#037F00] w-9 h-0.5"></div>
						</div>
						<div class="mt-5">
							<h3 class="text-lg font-semibold text-gray-800 dark:text-white">
								Арга хэмжээ, сайн дурын ажил
							</h3>
							<p class="mt-1 text-gray-600 dark:text-neutral-400">
								Хэн, юу, хэзээ, хаана, яагаад, яаж, ямар үйл ажиллагаа явуулж
								байгаа талаар мэдээлэл авах боломж
							</p>
						</div>
					</a>
				</div>
			</div>

			<div className="font-semibold flex flex-row items-center justify-center lg:w-[60%] text-center mt-4">
				<div className="text-xl md:text-2xl lg:text-3xl">
					Санал болгож буй хэрэгтэй файлууд
				</div>
			</div>
			<div className="text-gray-700 dark:text-gray-400 text-center lg:text-base text-sm">
				Бид зохиогчийн эрхэд халдахгүйн тулд бусад олж татаж болох холбоосыг
				оруулсан болно
			</div>
			{/* <div className="flex flex-row gap-6 overflow-y-auto w-full p-2 transition-all ease-in-out duration-300 delay-0 transform">
				<div>
					<div className="flex justify-center items-center flex-col gap-1 w-24 lg:w-28 transition-all ease-in-out duration-300 delay-0 transform">
						<img
							src={Ielts}
							className="w-24 h-24 lg:w-28 lg:h-28 rounded-lg border-2 dark:border-neutral-700 transition-all ease-in-out duration-300 delay-0 transform"
							alt=""
						/>
						<div className="">IELTS</div>
					</div>
				</div>
			</div> */}
			<div className="mt-8 flex justify-center w-full">
				<ul class="flex flex-col justify-end text-start -space-y-px w-full">
					{link.map(el => (
						<li class="flex items-center gap-x-2 p-3 text-sm bg-white border hover:bg-[#00000010] dark:hover:bg-[#ffffff10] text-gray-800 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
							<div class="w-full flex justify-between truncate">
								<div class="me-3 flex-1 w-full truncate">{el.n}</div>
								<a
									type="button"
									href={el.url}
									target="_blank"
									rel="noopener noreferrer"
									class="flex items-center gap-x-2 text-gray-500 hover:text-[#037F00] whitespace-nowrap dark:text-neutral-500 dark:hover:text-[#037F00]"
								>
									<svg
										class="flex-shrink-0 size-4"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									>
										<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
										<polyline points="7 10 12 15 17 10"></polyline>
										<line x1="12" x2="12" y1="15" y2="3"></line>
									</svg>
									Холбоос
								</a>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
