import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// import mhz from '../assets/bid.png';
import gh from '../assets/godhand.jpg';
import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from 'react';
import axios from 'axios';
import academy from '../assets/academy.svg';

export const Academy = () => {
	const mergejil = [];
	const [data, setData] = useState([]);

	useEffect(() => {
		document.title = 'Tmull ・ Бэлдэхэд туслах сургалтууд';
		const favicon = document.getElementById('favicon');
		favicon.setAttribute('href', academy);
		const dataRetriever = async () => {
			await axios.get(`https://tmull-be.vercel.app/acad`).then(response => {
				console.log(response?.data);
				setData(response?.data);
			});
		};
		dataRetriever();
	}, []);
	return (
		<div className="mt-24 lg:mt-28 dark:text-white">
			<div className="flex flex-col items-center justify-center my-16 ">
				<div className="font-semibold flex flex-col items-center justify-center mb-4">
					<div className="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform">
						Бэлдэхэд туслах <br /> сургалтууд
					</div>

					{/* <div className="text-lg lg:text-xl text-[#7c7c7c]">
            Чиглэллээ сонгон уу
          </div> */}
				</div>
				<div className=" flex flex-wrap w-full gap-4 justify-center items-center">
					{data.map((ell, index) => (
						<div
							onClick={() => {
								window.location.pathname = `/noots/surgalt/${ell._id}`;
							}}
						>
							<div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform">
								<div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
									<img
										className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform"
										src={
											'https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Acad%2F' +
											ell.img
										}
										alt=""
									/>
									<div className="text-[#656366] dark:text-[#9f9da0] p-2 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0">
										{ell.ca}
									</div>
								</div>

								<div className="w-full rounded-lg">
									<div className="font-bold text-xl lg:text-2xl truncate">
										{ell.na}
									</div>
									<div className="font-normal text-md lg:text-lg text-[#7c7c7c] truncate">
										{ell.di.replace(/\s*\/N\s*/g, '\n')}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
