import React from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	Link,
	useLocation,
} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// import mhz from '../assets/bid.png';
import gh from '../assets/godhand.jpg';
import nova from '../assets/nova.png';
import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from 'react';
import Comment from './comment';
import axios from 'axios';
export const Activities = () => {
	const mergejil = [];
	const [data, setData] = useState([]);
	const [load, setLoad] = useState(false);
	const location = useLocation();
	const bairshil = location.pathname;
	useEffect(() => {
		document.title = 'Tmull ・ Шүдний эмч';

		const dataRetriever = async () => {
			try {
				const response = await axios.get(
					`https://tmull-be.vercel.app/prof/${bairshil.split('/')[2]}`
				);

				if (response.status) {
					console.log(response?.data);
					setData(response?.data.data);
					setLoad(true);
				}
			} catch (error) {
				console.error('Error retrieving data:', error);
				// window.location.href = '/';
			}
		};

		dataRetriever();
	}, []);
	return (
		<div className="mt-24 lg:mt-28 dark:text-white">
			{load ? (
				<div>
					<div className="group/item relative flex justify-center flex-col items-center">
						<div className="flex justify-center items-center group/item cursor-pointer relative p-8">
							<div
								key="1"
								className="rounded-2xl w-32 h-32 lg:w-44 lg:h-44 ring-4 ring-[#656366] ring-offset-4 rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#037F00] transition delay-150 duration-500 ease-in-out"
							></div>
							<img
								src={`../${data.img}`}
								className="lg:w-36 lg:h-36 w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out"
								alt=""
							/>{' '}
						</div>
						<div className="capitalize font-semibold text-center drop-shadow absolute">
							<div class=" lg:group-hover/item:mt-60 group-hover/item:mt-48 lg:mt-72 mt-56 lg:group-hover/item:text-3xl group-hover/item:text-2xl transition-all delay-150 duration-500 ease-in-out text-2xl">
								{data.na}
							</div>
						</div>
					</div>
					<div className="mt-8 flex justify-center items-center font-semibold flex-col">
						<div className="flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
							<div className="text-md lg:text-lg text-[#7c7c7c] text-center xl:w-[60%] lg:w-[70%] w-[80%]">
								Уг мэргэжилтэй холбоотой мэдээлэл
							</div>
						</div>
						<div className="mt-8 gap-16 flex flex-col md:w-[80%] text-justify lg:text-lg">
							<div className="gap-2 flex flex-col">
								<div className="text-xl	lg:text-3xl text-center">
									Мэргэжлийн Тодрхойлолт
								</div>
								<div>{data.di}</div>
							</div>
							<div className="gap-2 flex flex-col">
								<div className="text-xl	lg:text-3xl text-center">
									Уг мэргэжил дээрх шилдэг их сургуулиуд
								</div>
								<div className="flex flex-wrap gap-4 lg:gap-10 justify-center text-lg lg:text-xl mt-2">
									{data.sch.map(el => {
										return (
											<div className="flex flex-row items-center w-96 bg-slate-500/10 overflow-hidden rounded-lg">
												<img
													className="w-24 h-24 lg:w-32 lg:h-32 bg-slate-500/10 p-2 object-cover"
													src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F${el.img}`}
													alt=""
												/>
												<div className="p-4 text-left">{el.n}</div>
											</div>
										);
									})}
								</div>
							</div>
							<div className="gap-2 flex flex-col">
								<div className="text-xl	lg:text-3xl text-center">
									Хийж болох хичээлээс гадуурх үйл ажиллагаа
								</div>
								<div className="flex flex-wrap gap-5 lg:gap-10 justify-center text-lg lg:text-xl mt-2">
									{data.tl.map(el => {
										return (
											<div className="flex flex-col items-center gap-2 w-full lg:w-96">
												<div className="p-2 text-center w-full bg-slate-500/10 overflow-hidden rounded-lg">
													{el.split(':')[0]}
												</div>
												<div className="text-base lg:text-lg">
													{el.split(':')[1]}
												</div>
											</div>
										);
									})}
								</div>
							</div>
							<div className="gap-2 flex flex-col">
								<div className="text-xl	lg:text-3xl text-center">
									Сайн байх хэрэгтэй зүйлс
								</div>
								<div className="flex flex-col gap-4 2xl:gap-10 justify-center text-lg lg:text-xl mt-2">
									{data.reqq.map(el => {
										return <div className="w-full">- {el}</div>;
									})}
								</div>
							</div>
							<div className="gap-2 flex flex-col">
								<div className="text-xl	lg:text-3xl text-center">
									Алдартай хүмүүс
								</div>
								<div className="flex justify-center gap-10 flex-wrap text-base lg:text-xl mt-2">
									{data.pl.map(el => {
										return (
											<div className="flex flex-row gap-4">
												<img
													className="rounded-xl object-cover
											lg:h-96 lg:w-full 2xl:w-48 w-36"
													src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F${el.img}`}
													alt=""
												/>
												<div className="w-full 2xl:w-80">{el.p}</div>
											</div>
										);
									})}
								</div>
							</div>
							<div className="flex flex-col md:w-full overflow-x-auto w-96">
								<div className="text-xl lg:text-2xl text-center transition-all ease-in-out duration-300 delay-0 transform w-full">
									Бичлэг
								</div>
								{data.vid.map(el => {
									return (
										<div
											target="_blank"
											className="hover:text-[#307000] text-ellipsis overflow-hidden uppercase p-1 lg:p-2 underline decoration-[#307000] decoration-2 border border-slate-200"
											rel="noopener noreferrer"
											href={el}
										>
											{el}
										</div>
									);
								})}
							</div>

							<div className="flex flex-col items-center justify-center">
								<div className="text-xl lg:text-2xl transition-all ease-in-out duration-300 delay-0 transform w-full">
									Эдгээр MBTI нь энэ мэргэжилд илүү тохиромжтой байдаг байна
								</div>
								<div className="flex flex-row text-2xl">
									{data.mbti.map(el => {
										return (
											<div className="bg-black/10 m-2 p-2 w-20 text-center rounded">
												{el}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					<Comment id={data._id} />
				</div>
			) : (
				<></>
			)}
		</div>
	);
};
