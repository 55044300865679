import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// import mhz from '../assets/bid.png';
import gh from '../assets/godhand.jpg';
// import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from 'react';
export const Video = () => {
	const mergejil = [];

	useEffect(() => {
		document.title = 'Tmull ・ Үзэх';
	}, []);
	return (
		<div className="mt-24 lg:mt-28 dark:text-white">
			<div>
				<div className="font-semibold flex flex-row items-center justify-center mb-4">
					<div className="text-2xl lg:text-4xl">Сүүлд нэмэгдсэн</div>
				</div>

				<div className="flex flex-row gap-4 overflow-x-auto w-full">
					<div
						onClick={() => {
							window.location.pathname = '/uzeh/asd';
						}}
					>
						<div className="w-80 h-44 lg:w-96 lg:h-48 rounded-lg relative ">
							<img
								className="w-80 h-44 lg:w-96 lg:h-48 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full bg-gradient-to-t from-[#000000] to-[#00000000] text-white rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-44 lg:w-96 lg:h-48 rounded-lg relative ">
							<img
								className="w-80 h-44 lg:w-96 lg:h-48 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full bg-gradient-to-t from-[#000000] to-[#00000000] text-white rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-44 lg:w-96 lg:h-48 rounded-lg relative ">
							<img
								className="w-80 h-44 lg:w-96 lg:h-48 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full bg-gradient-to-t from-[#000000] to-[#00000000] text-white rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-44 lg:w-96 lg:h-48 rounded-lg relative ">
							<img
								className="w-80 h-44 lg:w-96 lg:h-48 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full bg-gradient-to-t from-[#000000] to-[#00000000] text-white rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="font-semibold flex flex-row items-center justify-center lg:mt-6 mt-2">
				<div className="text-2xl lg:text-4xl"> Цуглуулга</div>
			</div>
			<div class="flex flex-col sm:inline-flex sm:flex-row rounded-lg shadow-sm lg:mt-6 mt-2">
				<button
					type="button"
					class="bg-[#037F00] text-white py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm focus:z-10 border border-gray-200 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
				>
					БҮГД
				</button>
				<button
					type="button"
					class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
				>
					SAT
				</button>
				<button
					type="button"
					class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
				>
					IELTS
				</button>
				<button
					type="button"
					class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
				>
					SCHOOL SELECTION
				</button>
				<button
					type="button"
					class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
				>
					RECOMMENDATION LETTER
				</button>
				<button
					type="button"
					class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
				>
					ESSAY
				</button>
				<button
					type="button"
					class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
				>
					PERSONAL STATEMENT
				</button>
			</div>
			<div>
				<div className="flex flex-wrap items-center justify-center gap-4 lg:mt-6 w-full mt-2">
					<div>
						<div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black m-2">
							<img
								className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black m-2">
							<img
								className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black m-2">
							<img
								className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black m-2">
							<img
								className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black m-2">
							<img
								className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black m-2">
							<img
								className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black m-2">
							<img
								className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
