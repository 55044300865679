import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// import mhz from '../assets/bid.png';
import gh from '../assets/godhand.jpg';
import nova from '../assets/nova.png';
import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import axios from 'axios';
import { useContext } from 'react';
import vol from '../assets/vol.svg';
export const EventComing = () => {
	const mergejil = [];
	const [data, setData] = useState([]);

	useEffect(() => {
		document.title = 'Tmull ・ Aрга хэмжээ болон сайн дурын үйл ажиллагаанууд';
		const favicon = document.getElementById('favicon');
		favicon.setAttribute('href', vol);
		const dataRetriever = async () => {
			await axios.get(`https://tmull-be.vercel.app/event`).then(response => {
				console.log(response?.data);
				setData(response?.data);
			});
		};
		dataRetriever();
	}, []);
	const right = () => {
		document.getElementById('content').scrollBy(1000, 0);
	};
	const left = () => {
		document.getElementById('content').scrollBy(-1000, 0);
	};
	const colors = [
		'#994C8E',
		'#E09626',
		'#037F00',
		'#5A9FDE',
		'#556EB0',
		'#6FAEA9',
	];

	return (
		<div className="mt-24 lg:mt-28 dark:text-white">
			<div class="flex flex-col items-center justify-center my-16">
				<div class="font-semibold flex flex-col items-center justify-center overflow-visible">
					<div
						onClick={() => {
							window.location.pathname = '/sain_duriin_ajil';
						}}
						class="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform"
					>
						Ойртож буй арга хэмжээ болон <br /> сайн дурын үйл ажиллагаанууд
					</div>
					<div class="text-lg lg:text-xl text-[#7c7c7c] text-center transition-all ease-in-out duration-300 delay-0 transform">
						Эдгээр үйл ажиллагаанд идэвхтэй оролцоорой
					</div>
				</div>
				<div class="inline-flex flex-row rounded-lg shadow-sm my-8">
					<button
						type="button"
						class="bg-[#037F00] hover:text-black text-white py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-t-md last:rounded-b-md first:rounded-s-md mt-0 first:ms-0 first:rounded-se-none last:rounded-es-none last:rounded-e-md text-sm focus:z-10 border border-gray-200 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
					>
						Бүгд
					</button>
					<button
						type="button"
						class="py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-t-md last:rounded-b-md first:rounded-s-md mt-0 first:ms-0 first:rounded-se-none last:rounded-es-none last:rounded-e-md text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
					>
						Event
					</button>
					<button
						type="button"
						class="py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-t-md last:rounded-b-md first:rounded-s-md mt-0 first:ms-0 first:rounded-se-none last:rounded-es-none last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
					>
						Volunteer
					</button>
					<button
						type="button"
						class="py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-t-md last:rounded-b-md first:rounded-s-md mt-0 first:ms-0 first:rounded-se-none last:rounded-es-none last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
					>
						Program
					</button>
				</div>
				<div class="w-full flex items-center justify-center relative">
					<div
						id="content"
						class="relative w-full flex-col flex gap-10 items-center"
					>
						<div class="snap-center shrink-0 hidden md:flex">
							<div class="shrink-0 md:w-48"></div>
						</div>
						{data.map(el => (
							<div
								onClick={() => {
									window.location.pathname = `/noots/uil_ajillagaa/${el._id}`;
								}}
								class="snap-always snap-center shrink-0 first:pl-8 last:pr-8"
							>
								<div>
									<div class="md:border-2 dark:border-zinc-800 rounded-lg">
										<div
											class="
 rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1"
										>
											<div class="flex flex-col items-center justify-between">
												<div class="flex flex-col xl:flex-row items-center font-semibold">
													<div class="w-80 lg:w-72 2xl:w-80 relative">
														<div className="absolute top-2 left-2 bg-[#00000060] text-white rounded-md px-1 text-sm lg:text-base">
															{el.t}
														</div>
														<img
															class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg"
															src={
																'https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Events%2F' +
																el.i
															}
															alt=""
														/>
													</div>
													<div class="lg:ml-2 xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
														<div class="text-lg xl:text-xl lg:hidden xl:block">
															{el.n}
														</div>
														<div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex lg:hidden xl:flex">
															<div>
																{new Date(el.sT).getUTCDay() === 1
																	? 'Дав, '
																	: new Date(el.sT).getUTCDay() === 2
																	? 'Мяг, '
																	: new Date(el.sT).getUTCDay() === 3
																	? 'Лха, '
																	: new Date(el.sT).getUTCDay() === 4
																	? 'Пүр, '
																	: new Date(el.sT).getUTCDay() === 5
																	? 'Баа, '
																	: new Date(el.sT).getUTCDay() === 6
																	? 'Бям, '
																	: new Date(el.sT).getUTCDay() === 7
																	? 'Ням, '
																	: ''}
																{new Date(el.sT).getUTCMonth() +
																	1 +
																	'-р сарын ' +
																	new Date(el.sT).getUTCDate() +
																	', ' +
																	new Date(el.sT).getUTCFullYear()}
															</div>
															<div>
																{new Date(el.sT).getUTCHours() +
																	':' +
																	(new Date(el.sT).getUTCMinutes() === 0
																		? '00'
																		: new Date(el.sT).getUTCMinutes())}
															</div>
														</div>
														<div class="xl:text-md h-40 overflow-hidden relative">
															<div className="bg-gradient-to-t from-white dark:from-black via-transparent to-transparent h-full w-full absolute"></div>
															{el.nf}
														</div>
													</div>
												</div>
												<div class="mt-4 w-full hidden xl:block font-semibold">
													<button
														onClick={() => {
															if (el.r === 'n') {
																return 0;
															} else {
																window.open(el.r);
															}
														}}
														class={
															el.r === 'n'
																? 'p-2 text-[#037F00] rounded-lg w-full bg-[#027f0018] cursor-not-allowed'
																: 'p-2 bg-[#037F00] rounded-lg w-full text-white cursor-pointer'
														}
													>
														Бүртгүүлэх {el.r === 'n' ? ' шаардлагагүй ' : ''}
													</button>
												</div>
											</div>
											<div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
												<div class="hidden lg:block xl:hidden font-semibold">
													<div class="text-md lg:text-lg xl:text-xl">
														{el.n}
													</div>
													<div class="text-[#656366] text-sm 2xl:text-base flex flex-row justify-between">
														<div>
															{new Date(el.sT).getUTCDay() === 1
																? 'Дав, '
																: new Date(el.sT).getUTCDay() === 2
																? 'Мяг, '
																: new Date(el.sT).getUTCDay() === 3
																? 'Лха, '
																: new Date(el.sT).getUTCDay() === 4
																? 'Пүр, '
																: new Date(el.sT).getUTCDay() === 5
																? 'Баа, '
																: new Date(el.sT).getUTCDay() === 6
																? 'Бям, '
																: new Date(el.sT).getUTCDay() === 7
																? 'Ням, '
																: ''}
															{new Date(el.sT).getUTCMonth() +
																1 +
																'-р сарын ' +
																new Date(el.sT).getUTCDate() +
																', ' +
																new Date(el.sT).getUTCFullYear()}
														</div>
														<div>
															{new Date(el.sT).getUTCHours() +
																':' +
																(new Date(el.sT).getUTCMinutes() === 0
																	? '00'
																	: new Date(el.sT).getUTCMinutes())}
														</div>
													</div>
												</div>
												{el.d.map((ell, index) => (
													<div class=" flex flex-wrap justify-between font-semibold w-full gap-y-1">
														<div class="">{Object.keys(ell)}:</div>
														<div
															style={{
																backgroundColor: colors[index % colors.length],
															}}
															class="text-sm px-1 xl:p-1 rounded-lg text-white flex justify-center items-center"
														>
															{ell[Object.keys(ell)[0]]}
														</div>
													</div>
												))}
												<button
													onClick={() => {
														if (el.r === 'n') {
															return 0;
														} else {
															window.open(el.r);
														}
													}}
													class={
														el.r === 'n'
															? 'block xl:hidden p-2 text-[#037F00] rounded-lg w-full bg-[#027f0018] cursor-not-allowed'
															: 'block xl:hidden p-2 bg-[#037F00] rounded-lg w-full text-white cursor-pointer'
													}
												>
													Бүртгүүлэх {el.r === 'n' ? ' шаардлагагүй ' : ''}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}

						<div class="snap-center shrink-0">
							<div class="shrink-0 md:w-48"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
