import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// import mhz from '../assets/bid.png';
import gh from '../assets/godhand.jpg';
import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import axios from 'axios';

export const Extracurricular = () => {
	const mergejil = [
		{
			id: 1,
			ner: './career/careers_people_man_accountant_finance_skin3.svg',
			ajil: 'нягтлан бодогч',
		},
		{
			id: 2,
			ner: './career/careers_people_man_dentist_skin3.svg',
			ajil: 'шүдний эмч',
		},
		{
			id: 3,
			ner: './career/careers_people_man_lab_tech_laboratory_technician_skin3.svg',
			ajil: 'лабораторийн техникч',
		},
		{
			id: 4,
			ner: './career/careers_people_man_marketing_manager_skin3.svg',
			ajil: 'маркетинг',
		},
		{
			id: 5,
			ner: './career/careers_people_man_medical_nurse_skin3.svg',
			ajil: 'сувилагч',
		},
		{
			id: 6,
			ner: './career/careers_people_man_paramedic_skin3.svg',
			ajil: 'түргэн тусламж',
		},
		{
			id: 7,
			ner: './career/careers_people_man_sales_finance_manager_skin3.svg',
			ajil: 'Санхүүгийн менежер',
		},
		{
			id: 8,
			ner: './career/careers_people_man_senior_librarian_skin3.svg',
			ajil: 'номын санч',
		},
		{
			id: 9,
			ner: './career/careers_people_man_software_developer_skin3.svg',
			ajil: 'хөгжүүлэгч',
		},
		{
			id: 10,
			ner: './career/careers_people_man_teacher_professor_skin3.svg',
			ajil: 'багш',
		},
		{
			id: 11,
			ner: './career/careers_people_woman_art_director_skin3.svg',
			ajil: 'Урлан бүтээгч',
		},
		{
			id: 12,
			ner: './career/careers_people_woman_engineer_architect_skin3.svg',
			ajil: 'архитекторч',
		},
		{
			id: 13,
			ner: './career/careers_people_woman_executive_director_manager_skin3.svg',
			ajil: 'менежер',
		},
		{
			id: 14,
			ner: './career/careers_people_woman_hr_human_resources_manager_skin3.svg',
			ajil: 'Хүний нөөцийн менежер',
		},
		{
			id: 15,
			ner: './career/careers_people_woman_lawyer_skin3.svg',
			ajil: 'хуульч',
		},
		{
			id: 16,
			ner: './career/careers_people_woman_medical_doctor_skin3.svg',
			ajil: 'эмч',
		},
		{
			id: 17,
			ner: './career/careers_people_woman_professor_teacher_skin3.svg',
			ajil: 'профессор',
		},
		{
			id: 18,
			ner: './career/careers_people_woman_public_relations_specialist_skin3.svg',
			ajil: 'олон нийтийн харилцааны мэргэжилтэн',
		},
		{
			id: 19,
			ner: './career/careers_people_woman_senior_psychologist_skin3.svg',
			ajil: 'сэтгэл зүйч',
		},
		{
			id: 20,
			ner: './career/careers_people_woman_vet_veterinarian_skin3.svg',
			ajil: 'малын эмч',
		},
		{
			id: 21,
			ner: './career/und.svg',
			ajil: 'шийдэгдээгүй',
		},
	];
	const colors = [
		'#994C8E',
		'#E09626',
		'#037F00',
		'#5A9FDE',
		'#556EB0',
		'#6FAEA9',
	];
	const [event, setEvent] = useState([]);
	const [prof, setProf] = useState([]);
	useEffect(() => {
		document.title = 'Tmull ・ Хичээлээс гадуурх үйл ажиллагаанууд';
		const event = async () => {
			await axios.get(`https://tmull-be.vercel.app/event`).then(response => {
				console.log(response?.data);
				setEvent(response?.data);
			});
		};
		const prof = async () => {
			await axios.get(`https://tmull-be.vercel.app/prof`).then(response => {
				console.log(response?.data);
				setProf(response?.data);
			});
		};
		event();
		prof();
	}, []);
	const right = () => {
		document.getElementById('content').scrollBy(1500, 0);
	};
	const left = () => {
		document.getElementById('content').scrollBy(-1500, 0);
	};
	return (
		<div className="font-semibold flex justify-center items-center flex-col mt-24 lg:mt-28 dark:text-white">
			<div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
				<div className="text-xl md:text-2xl lg:text-3xl dark:text-white">
					Чиглэл болгонтой холбоотой хичээлээс гадуурх үйл ажиллагаанууд
				</div>
			</div>
			<div className="flex flex-row w-full xl:flex-wrap xl:justify-center items-center my-4 overflow-y-auto relative xl:h-[50vh] 2xl:h-full dark:text-white">
				{prof.map((el, key) => {
					// console.log(el);
					return (
						<div
							onClick={() => {
								window.location.pathname = `/sain_duriin_ajil/${el._id}`;
							}}
							className="flex justify-center items-center group/item cursor-pointer p-8"
						>
							<div
								key={key}
								className="rounded-2xl w-32 h-32 ring-4 ring-[#656366] rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#037F00] transition delay-150 duration-500 ease-in-out"
							></div>
							<div className="h-32 w-32 flex items-end justify-end capitalize font-semibold transition-all ease-in-out duration-500 absolute opacity-25 group-hover/item:rotate-0 rotate-45 z-0 delay-150 group-hover/item:z-10 text-right group-hover/item:opacity-100 drop-shadow">
								<div class="rounded-2xl px-2 backdrop-blur-sm bg-[#ffffff60] dark:bg-[#00000060] w-full">
									{el.na}
								</div>
							</div>
							<img
								src={el.img}
								className="w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out grayscale "
								alt=""
							/>
						</div>
					);
				})}
			</div>
			<div class="flex flex-col items-center justify-center my-8 lg:my-16 w-full dark:text-white">
				<div
					onClick={() => {
						window.location.pathname = '/noots/uil_ajillagaa';
					}}
					class="font-semibold flex flex-col items-center justify-center mb-4 w-full relative"
				>
					<div class="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform cursor-pointer">
						Ойртож буй арга хэмжээ болон <br /> сайн дурын үйл ажиллагаанууд
					</div>
					<div class="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] text-center transition-all ease-in-out duration-300 delay-0 transform">
						Эдгээр үйл ажиллагаанд идэвхтэй оролцоорой
					</div>
					<div
						onClick={() => {
							window.location.pathname = '/noots/uil_ajillagaa';
						}}
						className="sm:text-lg text-center items-center hidden md:flex absolute right-0 font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#037F00] cursor-pointer"
					>
						Дэлгэрэнгүй{' '}
						<svg
							className="h-4"
							viewBox="0 0 366 359"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0_1639_2733)">
								<path
									d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
									className="group-hover/item:fill-[#037F00] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
								/>
							</g>
							<defs>
								<clipPath id="clip0_1639_2733">
									<rect width="366" height="359" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
					<div
						onClick={() => {
							window.location.pathname = '/sain_duriin_ajil';
						}}
						className="sm:text-lg text-center items-center md:hidden flex font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#037F00] cursor-pointer"
					>
						Дэлгэрэнгүй{' '}
						<svg
							className="h-4"
							viewBox="0 0 366 359"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0_1639_2733)">
								<path
									d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
									className="group-hover/item:fill-[#037F00] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
								/>
							</g>
							<defs>
								<clipPath id="clip0_1639_2733">
									<rect width="366" height="359" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
				</div>
				<div class="w-full flex items-center justify-center relative">
					<div class="hidden lg:flex w-[95%] 2xl:w-[85%] pointer-events-none flex-row justify-between absolute items-center z-30 h-full">
						<button onClick={left}>
							<svg
								class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform"
								viewBox="0 0 170 170"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M126 90C128.761 90 131 87.7614 131 85C131 82.2386 128.761 80 126 80V90ZM40.4645 81.4645C38.5118 83.4171 38.5118 86.5829 40.4645 88.5355L72.2843 120.355C74.2369 122.308 77.4027 122.308 79.3553 120.355C81.308 118.403 81.308 115.237 79.3553 113.284L51.0711 85L79.3553 56.7157C81.308 54.7631 81.308 51.5973 79.3553 49.6447C77.4027 47.692 74.2369 47.692 72.2843 49.6447L40.4645 81.4645ZM126 80L44 80V90L126 90V80Z"
									fill="#037F00"
								></path>
							</svg>
						</button>
						<button onClick={right}>
							<svg
								class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform"
								viewBox="0 0 170 170"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M44 90C41.2386 90 39 87.7614 39 85C39 82.2386 41.2386 80 44 80V90ZM129.536 81.4645C131.488 83.4171 131.488 86.5829 129.536 88.5355L97.7157 120.355C95.7631 122.308 92.5973 122.308 90.6447 120.355C88.692 118.403 88.692 115.237 90.6447 113.284L118.929 85L90.6447 56.7157C88.692 54.7631 88.692 51.5973 90.6447 49.6447C92.5973 47.692 95.7631 47.692 97.7157 49.6447L129.536 81.4645ZM44 80L126 80V90L44 90V80Z"
									fill="#037F00"
								></path>
							</svg>
						</button>
					</div>
					<div
						id="content"
						class="relative w-full flex gap-4 snap-x snap-mandatory overflow-x-auto scroll-smooth pb-4"
					>
						{event.map(el => (
							<div
								onClick={() => {
									window.location.pathname = `/noots/uil_ajillagaa/${el._id}`;
								}}
								class="snap-always snap-center shrink-0 first:pl-8 last:pr-8 ml-[0%] sm:ml-[8%] md:ml-[12%] lg:ml-[18%]"
							>
								<div>
									<div class="md:border-2 dark:border-zinc-800 rounded-lg">
										<div
											class="
 rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1"
										>
											<div class="flex flex-col items-center justify-between">
												<div class="flex flex-col xl:flex-row items-center font-semibold">
													<div class="w-80 lg:w-72 2xl:w-80">
														<img
															class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg"
															src={
																'https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Events%2F' +
																el.i
															}
															alt=""
														/>
													</div>
													<div class="lg:ml-2 xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
														<div class="text-lg xl:text-xl lg:hidden xl:block">
															{el.n}
														</div>
														<div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex lg:hidden xl:flex">
															<div>
																{new Date(el.sT).getUTCDay() === 1
																	? 'Дав, '
																	: new Date(el.sT).getUTCDay() === 2
																	? 'Мяг, '
																	: new Date(el.sT).getUTCDay() === 3
																	? 'Лха, '
																	: new Date(el.sT).getUTCDay() === 4
																	? 'Пүр, '
																	: new Date(el.sT).getUTCDay() === 5
																	? 'Баа, '
																	: new Date(el.sT).getUTCDay() === 6
																	? 'Бям, '
																	: new Date(el.sT).getUTCDay() === 7
																	? 'Ням, '
																	: ''}
																{new Date(el.sT).getUTCMonth() +
																	1 +
																	'-р сарын ' +
																	new Date(el.sT).getUTCDate() +
																	', ' +
																	new Date(el.sT).getUTCFullYear()}
															</div>
															<div>
																{new Date(el.sT).getUTCHours() +
																	':' +
																	(new Date(el.sT).getUTCMinutes() === 0
																		? '00'
																		: new Date(el.sT).getUTCMinutes())}
															</div>
														</div>
														<div class="xl:text-md h-40 overflow-hidden relative">
															<div className="bg-gradient-to-t from-white dark:from-black via-transparent to-transparent h-full w-full absolute"></div>
															{el.nf}
														</div>
													</div>
												</div>
												<div class="mt-4 w-full hidden xl:block font-semibold">
													<button
														onClick={() => {
															if (el.r === 'n') {
																return 0;
															} else {
																window.open(el.r);
															}
														}}
														class={
															el.r === 'n'
																? 'p-2 text-[#037F00] rounded-lg w-full bg-[#027f0018] cursor-not-allowed'
																: 'p-2 bg-[#037F00] rounded-lg w-full text-white cursor-pointer'
														}
													>
														Бүртгүүлэх {el.r === 'n' ? ' шаардлагагүй ' : ''}
													</button>
												</div>
											</div>
											<div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
												<div class="hidden lg:block xl:hidden font-semibold">
													<div class="text-md lg:text-lg xl:text-xl">
														{el.n}
													</div>
													<div class="text-[#656366] text-sm 2xl:text-base flex flex-row justify-between">
														<div>
															{new Date(el.sT).getUTCDay() === 1
																? 'Дав, '
																: new Date(el.sT).getUTCDay() === 2
																? 'Мяг, '
																: new Date(el.sT).getUTCDay() === 3
																? 'Лха, '
																: new Date(el.sT).getUTCDay() === 4
																? 'Пүр, '
																: new Date(el.sT).getUTCDay() === 5
																? 'Баа, '
																: new Date(el.sT).getUTCDay() === 6
																? 'Бям, '
																: new Date(el.sT).getUTCDay() === 7
																? 'Ням, '
																: ''}
															{new Date(el.sT).getUTCMonth() +
																1 +
																'-р сарын ' +
																new Date(el.sT).getUTCDate() +
																', ' +
																new Date(el.sT).getUTCFullYear()}
														</div>
														<div>
															{new Date(el.sT).getUTCHours() +
																':' +
																(new Date(el.sT).getUTCMinutes() === 0
																	? '00'
																	: new Date(el.sT).getUTCMinutes())}
														</div>
													</div>
												</div>
												{el.d.map((ell, index) => (
													<div class=" flex flex-wrap justify-between font-semibold w-full gap-y-1">
														<div class="">{Object.keys(ell)}:</div>
														<div
															style={{
																backgroundColor: colors[index % colors.length],
															}}
															class="text-sm px-1 xl:p-1 rounded-lg text-white flex justify-center items-center"
														>
															{ell[Object.keys(ell)[0]]}
														</div>
													</div>
												))}
												<button
													onClick={() => {
														if (el.r === 'n') {
															return 0;
														} else {
															window.open(el.r);
														}
													}}
													class={
														el.r === 'n'
															? 'block xl:hidden p-2 text-[#037F00] rounded-lg w-full bg-[#027f0018] cursor-not-allowed'
															: 'block xl:hidden p-2 bg-[#037F00] rounded-lg w-full text-white cursor-pointer'
													}
												>
													Бүртгүүлэх {el.r === 'n' ? ' шаардлагагүй ' : ''}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
						<div class="snap-center shrink-0">
							<div class="shrink-0 md:w-48"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-full hidden mt-16 justify-center items-center flex-col rounded-lg bg-[#54545428] py-2">
				<div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
					<div className="text-xl md:text-2xl lg:text-3xl">
						Хичээлээс гадуурх үйл ажиллагаатай холбоотой мэдээллүүд
					</div>
				</div>
				<div className="flex flex-row overflow-x-auto gap-4 p-2 w-full">
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-16 flex justify-center items-center flex-col">
				<div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
					<div className="text-xl md:text-2xl lg:text-3xl">
						Чиглэлээ сонгоогүй байсан ч танд хийж болох боломжтой олон юм бий
					</div>
				</div>
				<div className="mt-8 gap-8 flex flex-col md:w-[80%] lg:w-[60%]">
					<div className="flex flex-row gap-4">
						<div className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 lg:p-8 md:p-6 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center p-4">
							<svg
								width="250"
								height="250"
								viewBox="0 0 250 250"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_1435_2637)">
									<path
										d="M204.996 231.283C201.877 231.283 198.757 231.283 195.638 230.763C185.76 229.724 172.243 224.005 162.366 210.488C155.087 200.61 148.329 183.974 154.047 157.98C156.646 147.582 162.885 135.625 169.644 123.148C184.201 96.1135 201.357 65.4406 184.201 37.3669C178.482 27.489 170.684 22.2905 161.326 20.2108C137.931 16.0519 110.897 35.8073 110.897 36.3273L107.778 32.1684C106.738 33.208 105.698 33.7281 105.698 34.2481L98.4199 25.4103C119.215 7.21451 169.124 -17.7399 202.916 18.6516C234.109 52.4438 236.709 106.512 236.709 109.111C237.229 126.787 238.268 141.864 239.308 155.381C241.907 190.213 242.947 211.528 231.51 222.966C225.272 228.684 216.953 231.283 204.996 231.283ZM173.283 12.9326C181.081 16.5719 187.84 22.8105 193.558 31.6484C213.834 64.921 194.598 100.273 179.522 128.867C173.283 140.824 167.044 151.741 164.965 160.58C161.325 177.736 163.405 192.812 171.203 203.73C179.002 214.127 189.399 218.806 196.158 219.326C209.675 220.366 218.513 219.326 223.192 214.647C231.51 206.849 229.95 186.574 227.871 155.901C226.831 142.384 225.791 126.787 225.272 109.111C225.272 108.591 222.672 56.6032 194.599 25.9298C187.84 19.1712 180.561 15.0123 173.283 12.9326Z"
										fill="#037F00"
									/>
									<path
										d="M60.9886 91.9551C56.8297 91.9551 54.75 84.1569 54.75 76.8787C54.75 69.6005 56.8297 61.8022 60.9886 61.8022C62.0282 61.8022 63.0683 62.8419 63.0683 63.8819C63.0683 64.922 62.0287 65.4416 61.5086 65.9616C60.9886 66.4817 58.9094 70.1205 58.9094 77.3992C58.9094 84.6779 60.9891 88.3168 61.5086 88.8368C62.5482 88.8368 63.0683 89.8764 63.0683 90.9165C63.0683 90.915 62.0282 91.9551 60.9886 91.9551Z"
										fill="#415A6B"
									/>
									<path
										d="M64.1078 49.3245C64.1078 49.3245 74.5053 40.4866 81.2639 41.0062C88.0226 42.0458 91.1419 55.043 91.1419 55.043C91.1419 55.043 87.5025 68.0402 81.784 67.5202C76.0654 67.0002 62.0281 52.9638 64.1078 49.3245Z"
										fill="#037F00"
									/>
									<path
										d="M81.2643 67.5202C70.3467 62.3212 90.6222 55.043 90.6222 55.043C90.6222 55.563 87.5029 68.0402 81.2643 67.5202Z"
										fill="#F05540"
									/>
									<path
										d="M57.3494 69.6C52.1505 63.3614 53.1905 52.9639 59.9487 44.6456C60.9883 43.086 62.548 42.5659 64.6276 42.5659C66.7073 42.5659 68.267 43.086 69.3066 44.6456L83.8635 61.8018C84.9031 63.3614 85.4231 64.921 85.4231 67.0007C84.9031 68.5604 83.8635 70.12 82.3038 71.1596C72.9459 76.3586 62.5484 75.8386 57.3494 69.6ZM66.1878 47.2449C65.6677 46.7248 65.1482 46.7248 64.6281 46.7248C64.1081 46.7248 63.5885 46.7248 63.0685 47.2449C57.3499 54.0035 56.3098 62.3213 60.4692 67.0003C64.6286 71.6792 72.9464 71.6792 80.2246 67.5203C80.7447 67.0003 81.2642 66.4807 81.2642 65.9607C81.2642 65.4406 81.2642 64.921 80.7442 64.401L66.1878 47.2449Z"
										fill="#CED6E0"
									/>
									<path
										d="M82.304 59.2024L69.8268 44.6455C68.2672 45.6851 67.2276 46.2052 66.1875 47.2448L78.6647 61.8016C79.7048 61.2816 80.7444 60.242 82.304 59.2024Z"
										fill="#E8EAE9"
									/>
									<path
										d="M90.543 54.9641L78.6972 41.1518L103.559 19.8295L115.405 33.6419L90.543 54.9641Z"
										fill="#CED6E0"
									/>
									<path
										d="M80.7438 67.0007C74.5052 64.921 62.548 52.9639 64.1077 49.3245C64.1077 49.3245 65.1473 48.2849 66.7069 47.2448C67.7465 46.7248 68.7866 45.6852 70.3463 44.6456C73.9856 42.5659 78.1445 40.4867 81.2638 41.0063C88.0224 42.0459 91.1417 55.0431 91.1417 55.0431C91.1417 55.0431 85.9427 56.6027 82.3039 59.202C81.2643 60.2416 79.7046 60.7616 79.1846 61.8012C77.6245 63.8814 78.1445 65.4411 80.7438 67.0007Z"
										fill="#037F00"
									/>
									<path
										d="M80.2491 107.175H40.7384V88.4595C40.7332 87.2292 40.9717 86.01 41.4402 84.8723C41.9086 83.7347 42.5977 82.701 43.4677 81.831C44.3377 80.961 45.3714 80.2719 46.5091 79.8035C47.6467 79.335 48.8659 79.0965 50.0963 79.1016H71.4113C72.6416 79.0965 73.8608 79.335 74.9985 79.8035C76.1362 80.2719 77.1698 80.961 78.0398 81.831C78.9098 82.701 79.5989 83.7347 80.0674 84.8723C80.5358 86.01 80.7743 87.2292 80.7692 88.4595V107.175H80.2491ZM51.6559 94.6981H68.812C70.8917 94.6981 72.4514 93.1385 72.4514 91.0588C72.4514 88.9791 70.8917 87.4194 68.812 87.4194H51.6559C49.5762 87.4194 48.0166 88.9791 48.0166 91.0588C48.0166 92.6184 49.5762 94.6981 51.6559 94.6981Z"
										fill="#55C0EB"
									/>
									<path
										d="M112.977 112.874V240.765C112.982 241.995 112.744 243.214 112.275 244.352C111.807 245.49 111.118 246.523 110.248 247.393C109.378 248.263 108.344 248.952 107.206 249.421C106.069 249.889 104.849 250.128 103.619 250.123H18.358C17.1276 250.128 15.9085 249.889 14.7708 249.421C13.6331 248.952 12.5994 248.263 11.7294 247.393C10.8595 246.523 10.1704 245.49 9.7019 244.352C9.23345 243.214 8.99492 241.995 9.00008 240.765V112.874C8.99492 111.643 9.23345 110.424 9.7019 109.286C10.1704 108.149 10.8595 107.115 11.7294 106.245C12.5994 105.375 13.6331 104.686 14.7708 104.218C15.9085 103.749 17.1276 103.511 18.358 103.516H103.619C104.849 103.51 106.068 103.749 107.206 104.217C108.344 104.686 109.377 105.375 110.247 106.245C111.117 107.115 111.807 108.149 112.275 109.286C112.744 110.424 112.982 111.643 112.977 112.874Z"
										fill="#55C0EB"
									/>
									<path
										d="M96.3408 143.943V208.929H103.62V240.122H18.3584V208.929H25.6371V143.943H18.3584V112.23H103.62V143.943H96.3408Z"
										fill="#E8EAE9"
									/>
									<path
										d="M60.9889 91.9552C59.9493 91.9552 58.9092 90.9156 58.9092 89.8755C58.9092 88.8359 59.9488 88.3158 60.4688 87.7958C60.9889 87.2757 63.0681 83.6369 63.0681 76.3582C63.0681 69.08 60.9884 65.4407 60.4688 64.9206C59.4292 64.9206 58.9092 63.881 58.9092 62.8409C58.9092 61.8008 59.9488 60.7612 60.9889 60.7612C65.1478 60.7612 67.2275 68.5595 67.2275 75.8377C67.2275 84.1569 65.1478 91.9552 60.9889 91.9552Z"
										fill="#415A6B"
									/>
									<path
										d="M38.6331 133.026H83.3429V182.414H38.6331V133.026ZM32.3945 200.091H89.0619V208.928H32.3945V200.091ZM32.3945 221.406H89.0619V230.244H32.3945V221.406Z"
										fill="#ADB3BA"
									/>
								</g>
								<defs>
									<clipPath id="clip0_1435_2637">
										<rect width="250" height="250" fill="white" />
									</clipPath>
								</defs>
							</svg>
						</div>
						<div className="">
							<div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
								Internship
							</div>
							<div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#202020] 2xl:text-left text-justify dark:text-[#d1d1d1]">
								An internship is a professional learning experience that offers
								meaningful, practical work related to a student's field of study
								or career interest. An internship gives a student the
								opportunity for career exploration and development, and to learn
								new skills.
							</div>
						</div>
					</div>
					<div className="flex flex-row gap-4">
						<div className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 lg:p-8 md:p-6 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center p-4">
							<svg
								width="217"
								height="246"
								viewBox="0 0 217 246"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_1464_2745)">
									<path
										d="M107.508 240.038H210.017V122.529C201.416 119.149 194.3 113.368 189.33 106.157C180.089 92.7362 179.687 14.3821 179.5 13.019L135.981 5.01904C141.576 40.7865 130.29 83.3602 107.508 122.529M107.508 240.038V122.529ZM107.508 240.038H5V122.529C22.1977 115.77 33.4746 99.4031 33.4746 81.2039V12.7671L79.0339 5.01904C73.4418 40.786 84.7281 83.3587 107.508 122.529"
										fill="#E8EAE9"
									/>
									<path
										d="M107.508 240.038H210.017V122.529C201.416 119.149 194.3 113.368 189.33 106.157C180.089 92.7362 179.687 14.3821 179.5 13.019L135.981 5.01904C141.576 40.7865 130.29 83.3602 107.508 122.529M107.508 240.038V122.529M107.508 240.038H5V122.529C22.1977 115.77 33.4746 99.4031 33.4746 81.2039V12.7671L79.0339 5.01904C73.4418 40.786 84.7281 83.3587 107.508 122.529"
										stroke="#5B819A"
										stroke-width="10"
										stroke-miterlimit="10"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M187 79C203.569 79 217 65.5685 217 49C217 32.4315 203.569 19 187 19C170.431 19 157 32.4315 157 49C157 65.5685 170.431 79 187 79Z"
										fill="#CED6E0"
									/>
									<path
										d="M140.008 115.375H165.01M50 115.375H75.002M158.759 210.033V167.427M56.2549 210.033V168.325"
										stroke="#5B819A"
										stroke-width="10"
										stroke-miterlimit="10"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M84.5121 147.878L56.2551 175.014L27.9932 147.878C29.8009 146.105 32.2711 145.009 35.0051 145.009H77.5051C80.2392 145.009 82.7093 146.105 84.5121 147.878ZM187.022 147.878L158.76 175.014L130.503 147.878C132.311 146.105 134.781 145.009 137.51 145.009H180.015C182.749 145.009 185.219 146.105 187.022 147.878Z"
										fill="#CED6E0"
									/>
									<path
										d="M190.015 155.014V200.014C190.015 205.537 185.538 210.014 180.015 210.014H137.51C131.987 210.014 127.51 205.537 127.51 200.014V155.014C127.51 149.495 131.986 145.009 137.51 145.009H180.015C185.537 145.009 190.015 149.489 190.015 155.014ZM87.505 155.014V200.014C87.505 205.537 83.0279 210.014 77.505 210.014H35.005C29.4771 210.014 25 205.537 25 200.014V155.014C25 149.477 29.491 145.009 35.005 145.009H77.505C83.0269 145.009 87.505 149.489 87.505 155.014Z"
										stroke="#5B819A"
										stroke-width="10"
										stroke-miterlimit="10"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M194.653 38.2742C189.674 38.1838 187.085 41.9473 187.085 41.9473C187.085 41.9473 184.634 38.0922 179.655 38.0012C175.215 37.921 172.084 41.7758 172.002 46.3298C171.906 51.6014 175.963 57.9144 186.692 63.5321C197.618 58.3081 201.902 52.1467 201.998 46.8753C202.081 42.3213 199.092 38.3553 194.653 38.2742Z"
										fill="#037F00"
									/>
									<path
										d="M179.5 13.019L135.981 5.01904M33.4746 12.7671L79.0339 5.01904M79.0342 5C99.5357 18.624 115.481 18.624 135.983 5"
										stroke="#5B819A"
										stroke-width="10"
										stroke-miterlimit="10"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</g>
								<defs>
									<clipPath id="clip0_1464_2745">
										<rect width="217" height="246" fill="white" />
									</clipPath>
								</defs>
							</svg>
						</div>
						<div className="">
							<div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
								Volunteer
							</div>
							<div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#202020] 2xl:text-left text-justify dark:text-[#d1d1d1]">
								Volunteering is the act of rendering selfless service to a
								cause, oftentimes for free and without coercion. It is about
								giving time to a good cause, specifically to help an
								organization, your community or an individual you are not
								related to. People who engage in this type of activity are known
								as volunteers.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-8 flex justify-center flex-col w-full items-center">
				<div className="font-semibold flex flex-row items-center justify-center mb-4">
					<div className="text-2xl lg:text-4xl">Сэтгэгдлийн хэсэг</div>
				</div>
				<div className="gap-4 flex flex-col lg:w-[60%] w-[95%]">
					<div className="w-full">
						<div className="relative div ">
							{/* <input
                type="text"
                placeholder=" "
                id="Feedback"
                className="rounded-md shadow-sm border-2 sm:text-sm outline-none focus:ring-2 ring-black"
              /> */}
							<textarea
								id="Feedback"
								rows="4"
								placeholder=" "
								className="dark:bg-neutral-900 rounded-md shadow-sm border-2 text-sm outline-none ring-0 dark:border-none focus:ring-2 ring-black dark:ring-neutral-600 input w-full max-h-44 lg:max-h-72 min-h-16"
							></textarea>
							<label
								for="Feedback"
								className="bg-white dark:bg-neutral-900 px-1 rounded-md text-sm md:text-base"
							>
								Сэтгэгдэл
							</label>
						</div>
						<div className="mt-3 w-full flex justify-end">
							<button class="transition ease-in-out duration-300 px-4 bg-black rounded-lg text-white py-2 dark:bg-neutral-300 hover:scale-110 hover:bg-gray-950 dark:text-black">
								Илгээх
							</button>
						</div>
					</div>
					<div className="w-full gap-12 flex flex-col overflow-y-auto">
						<div>
							<div className="flex flex-row items-center gap-2">
								<div>
									<svg
										width="35"
										height="35"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									{' '}
									<div>Нэр</div>
									<div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
										2 долоо хоногийн өмнө
									</div>
								</div>
							</div>
							<div className="mt-2">
								excellent in career preparation and excelled speed in being
								hired within their chosen field .Franklin and Marshall
								encourages and expects maturity and self discipline .Serious and
								fun too with a large variety of sports, clubs and activities for
								all.
							</div>
							<div className="flex flex-row mt-2 w-full border-t-2 pt-2 text-sm ">
								<div>Та уг сэтгэгдэлтэй санаа нийлж байна уу?</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-green-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
							</div>
						</div>
						<div>
							<div className="flex flex-row items-center gap-2">
								<div>
									<svg
										width="35"
										height="35"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									{' '}
									<div>Нэр</div>
									<div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
										2 долоо хоногийн өмнө
									</div>
								</div>
							</div>
							<div className="mt-2">
								excellent in career preparation and excelled speed in being
								hired within their chosen field .Franklin and Marshall
								encourages and expects maturity and self discipline .Serious and
								fun too with a large variety of sports, clubs and activities for
								all.
							</div>
							<div className="flex flex-row mt-2 w-full border-t-2 pt-2 text-sm ">
								<div>Та уг сэтгэгдэлтэй санаа нийлж байна уу?</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-green-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
							</div>
						</div>
						<div>
							<div className="flex flex-row items-center gap-2">
								<div>
									<svg
										width="35"
										height="35"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									{' '}
									<div>Нэр</div>
									<div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
										2 долоо хоногийн өмнө
									</div>
								</div>
							</div>
							<div className="mt-2">
								excellent in career preparation and excelled speed in being
								hired within their chosen field .Franklin and Marshall
								encourages and expects maturity and self discipline .Serious and
								fun too with a large variety of sports, clubs and activities for
								all.
							</div>
							<div className="flex flex-row mt-2 w-full border-t-2 pt-2 text-sm ">
								<div>Та уг сэтгэгдэлтэй санаа нийлж байна уу?</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-green-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
							</div>
						</div>
						<div>
							<div className="flex flex-row items-center gap-2">
								<div>
									<svg
										width="35"
										height="35"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									{' '}
									<div>Нэр</div>
									<div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
										2 долоо хоногийн өмнө
									</div>
								</div>
							</div>
							<div className="mt-2">
								excellent in career preparation and excelled speed in being
								hired within their chosen field .Franklin and Marshall
								encourages and expects maturity and self discipline .Serious and
								fun too with a large variety of sports, clubs and activities for
								all.
							</div>
							<div className="flex flex-row mt-2 w-full border-t-2 pt-2 text-sm ">
								<div>Та уг сэтгэгдэлтэй санаа нийлж байна уу?</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-green-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
							</div>
						</div>
						<div>
							<div className="flex flex-row items-center gap-2">
								<div>
									<svg
										width="35"
										height="35"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									{' '}
									<div>Нэр</div>
									<div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
										2 долоо хоногийн өмнө
									</div>
								</div>
							</div>
							<div className="mt-2">
								excellent in career preparation and excelled speed in being
								hired within their chosen field .Franklin and Marshall
								encourages and expects maturity and self discipline .Serious and
								fun too with a large variety of sports, clubs and activities for
								all.
							</div>
							<div className="flex flex-row mt-2 w-full border-t-2 pt-2 text-sm ">
								<div>Та уг сэтгэгдэлтэй санаа нийлж байна уу?</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-green-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
							</div>
						</div>
						<div>
							<div className="flex flex-row items-center gap-2">
								<div>
									<svg
										width="35"
										height="35"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									{' '}
									<div>Нэр</div>
									<div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
										2 долоо хоногийн өмнө
									</div>
								</div>
							</div>
							<div className="mt-2">
								excellent in career preparation and excelled speed in being
								hired within their chosen field .Franklin and Marshall
								encourages and expects maturity and self discipline .Serious and
								fun too with a large variety of sports, clubs and activities for
								all.
							</div>
							<div className="flex flex-row mt-2 w-full border-t-2 pt-2 text-sm ">
								<div>Та уг сэтгэгдэлтэй санаа нийлж байна уу?</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-green-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
							</div>
						</div>
						<div>
							<div className="flex flex-row items-center gap-2">
								<div>
									<svg
										width="35"
										height="35"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									{' '}
									<div>Нэр</div>
									<div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
										2 долоо хоногийн өмнө
									</div>
								</div>
							</div>
							<div className="mt-2">
								excellent in career preparation and excelled speed in being
								hired within their chosen field .Franklin and Marshall
								encourages and expects maturity and self discipline .Serious and
								fun too with a large variety of sports, clubs and activities for
								all.
							</div>
							<div className="flex flex-row mt-2 w-full border-t-2 pt-2 text-sm ">
								<div>Та уг сэтгэгдэлтэй санаа нийлж байна уу?</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-green-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
								<div className="flex items-center flex-row justify-center">
									<svg
										className="h-4 lg:h-5 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
										viewBox="0 0 311 282"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
											className="stroke-black dark:stroke-neutral-400"
											stroke-width="20"
										/>
									</svg>
									<div>5</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
