import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import pdf from './1.pdf';
import iurl from './12.jpg';
function Pdfcomp() {
	const path = './1.pdf';
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	function changePage(offset) {
		setPageNumber(prevPageNumber => prevPageNumber + offset);
	}

	function previousPage() {
		changePage(-1);
	}

	function nextPage() {
		changePage(1);
	}

	return (
		<div>
			<Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
				<Page
					pageNumber={pageNumber}
					renderTextLayer={false}
					renderAnnotationLayer={false}
				/>
			</Document>
			<div>
				<p>
					Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
				</p>
				<button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
					Previous
				</button>
				<button
					type="button"
					disabled={pageNumber >= numPages}
					onClick={nextPage}
				>
					Next
				</button>
			</div>
		</div>
	);
}
export default Pdfcomp;
// import { useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import pdf from './1.pdf';
// import iurl from './12.jpg';
// function Pdfcomp() {
// 	const path = './1.pdf';
// 	const [numPages, setNumPages] = useState();
// 	const [pageNumber, setPageNumber] = useState(1);

// 	function onDocumentLoadSuccess({ numPages }) {
// 		setNumPages(numPages);
// 	}

// 	return (
// 		<div>
// 			url {pdf}
// 			<img src={iurl} alt="" />
// 			<Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
// 				<Page
// 					pageNumber={pageNumber}
// 					renderTextLayer={false}
// 					renderAnnotationLayer={false}
// 				/>
// 			</Document>
// 			<p>
// 				Page {pageNumber} of {numPages}
// 			</p>
// 		</div>
// 	);
// }
// export default Pdfcomp;
