import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
// import { useContext } from 'react';
// import ReactDOM from 'react-dom';
// import { PDFViewer } from '@react-pdf/renderer';
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import yesh from '../assets/yesh.png';
import { Document, Page, pdfjs } from 'react-pdf';
import Pdfcomp from './pdfcomp';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.mjs',
	import.meta.url
).toString();

// pdfjs.GlobalWorkerOptions.workerSrc =
// 	'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js';

// import { pdfjs } from 'react-pdf';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const TestCategory = () => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	function changePage(offset) {
		setPageNumber(prevPageNumber => prevPageNumber + offset);
	}

	function previousPage() {
		changePage(-1);
	}

	function nextPage() {
		changePage(1);
	}
	const test = [
		{
			tName: 'Англи хэлний элсэлтийн шалгалт',
			material: { year: 2023, var: [{ vName: 'A' }] },
		},
	];

	const [qfirst, setQfirst] = useState(false);
	useEffect(() => {
		document.title = 'Tmull ・ Туршилтын шалгалт';
	}, []);
	return (
		<div className="mt-24 lg:mt-28 font-semibold dark:text-white">
			<div className="mt-8 lg:mt-12 xl:mt-16 2xl:mt-20 flex justify-center flex-col items-center">
				<div className="text-[#4BAB4D]">Туршилтын шалгалтууд</div>
				<div className="text-lg xl:text-xl 2xl:text-2xl w-full text-center">
					Өөрийгөө сорьж, чадвараа хөгжүүлж, туршлага хуримтлуулцгаая
				</div>
				<div class="flex flex-wrap gap-3 lg:gap-6 mt-4 w-full justify-center items-center">
					<div className="w-full cursor-pointer overflow-hidden md:w-72 lg:w-96 bg-neutral-100 dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 drop-shadow rounded-lg flex justify-center">
						<img className="w-full object-cover" src={yesh} alt="" />
					</div>
					<div className="w-full cursor-pointer overflow-hidden md:w-72 lg:w-96 bg-neutral-100 dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 drop-shadow rounded-lg flex justify-center">
						<img className="w-full object-cover" src={yesh} alt="" />
					</div>
					<div className="w-full cursor-pointer overflow-hidden md:w-72 lg:w-96 bg-neutral-100 dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 drop-shadow rounded-lg flex justify-center">
						<img className="w-full object-cover" src={yesh} alt="" />
					</div>
				</div>
				<iframe
					title="20023.pdf"
					className="aspect-square h-[80vh]"
					src="https://docs.google.com/document/d/e/2PACX-1vSR92qW0ajC_dXBqCeZqsf6Bi3lMP6wj8S0xGeXX-5UL7fZ1z5BwMHgtYZucncgEWOTpra_RvHyc2eB/pub?embedded=true"
				></iframe>
			</div>
			{/* <>
				<Document
					file="http://english.zereg.net//2023-%D0%AD%D0%95%D0%A8-variation-D.pdf"
					onLoadSuccess={onDocumentLoadSuccess}
				>
					<Page pageNumber={pageNumber} />
				</Document>
				<div>
					<p>
						Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
					</p>
					<button
						type="button"
						disabled={pageNumber <= 1}
						onClick={previousPage}
					>
						Previous
					</button>
					<button
						type="button"
						disabled={pageNumber >= numPages}
						onClick={nextPage}
					>
						Next
					</button>
				</div>
			</> */}

			<Pdfcomp />
		</div>
	);
};
